import { FC, useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import AutoHideAlert from "../alert/AutoHideAlert";
import { RevitMartContactFormService } from "../../services/RevitMartContactFormService";
import { IFormData } from "../../interfaces/FormData";
import { Captcha } from "../recaptcha/Recaptcha";

type FormSource = {
  formSource: string;
};

const RequestFrom: FC<FormSource> = ({ formSource }) => {
  const initialFormData: IFormData = {
    source: formSource,
    name: "",
    email: "",
    message: "",
  };

  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState<IFormData>(initialFormData);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [captchaError, setCaptchaError] = useState<string>();
  const [captchaToken, setCaptchaToken] = useState<string | undefined>(
    undefined
  );

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      if (captchaToken) {
        setIsLoading(true);
        await RevitMartContactFormService(formData)
          .then((response) => {
            if (response) {
              setFormData(initialFormData);
              setSuccessMessage(
                "Your message has been sent and someone will be in touch with you shortly"
              );
              setIsLoading(false);
              setValidated(false);
              setCaptchaToken(undefined);
            }
          })
          .catch((error) => {
            console.error("Error in form submission:", error);
            setIsLoading(false);
            setErrorMessage("Failed to send message.");
          });
      } else {
        setCaptchaError("Please confirm that you are not a robot.");
      }
    }
  };

  return (
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Form.Control
        type="text"
        name="source"
        value="revitmart"
        readOnly
        hidden
      />
      <Row className="g-4 my-5">
        <Col xs={12} md={8}>
          <Form.Group controlId="name">
            <Form.Label>Your Name </Form.Label>
            <Form.Control
              type="text"
              name="name"
              required
              autoComplete="off"
              placeholder="Your Name"
              onChange={handleChange}
              value={formData.name}
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={8}>
          <Form.Group controlId="email">
            <Form.Label>Your E-Mail Address</Form.Label>
            <Form.Control
              type="email"
              name="email"
              required
              autoComplete="off"
              placeholder="Your E-Mail Address"
              onChange={handleChange}
              value={formData.email}
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={8}>
          <Form.Group controlId="message">
            <Form.Label>
              Comments (Include any relevant details pertinent to this
              position.)
            </Form.Label>
            <Form.Control
              as="textarea"
              name="message"
              required
              rows={10}
              autoComplete="off"
              placeholder="Comments"
              onChange={handleChange}
              value={formData.message}
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={8}>
          <Captcha
            error={captchaError}
            captchaToken={captchaToken}
            setCaptchaToken={setCaptchaToken}
            setCaptchaError={setCaptchaError}
          />
        </Col>
        <Col xs={12} md={8}>
          <Button variant="dark" type="submit" disabled={isLoading}>
            {isLoading ? "Sending" : "Send"}
          </Button>
        </Col>

        <Col xs={12} md={8}>
          {successMessage && (
            <AutoHideAlert
              variant="success"
              message={successMessage}
              duration={3000}
              setMessage={setSuccessMessage}
            />
          )}
          {errorMessage && (
            <AutoHideAlert
              variant="danger"
              message={errorMessage}
              duration={3000}
              setMessage={setErrorMessage}
            />
          )}
        </Col>
      </Row>
    </Form>
  );
};
export default RequestFrom;
