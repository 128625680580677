import { FC } from "react";
import { Carousel } from "react-bootstrap";
import { CarouselSliders } from "./CarouselSlider.constant";
import "./CarouselSlider.style.scss";

const CarouselSlider: FC = () => {
  return (
    <div className="slider">
      <Carousel>
        {CarouselSliders.slides.map((slide, index) => (
          <Carousel.Item key={`slide-${index}`}>
            <div
              style={{
                backgroundImage: `url(${slide.image})`,
              }}
              className="slide"
            >
              <div className="slide-content">
                {CarouselSliders.contents.map((content, index) => (
                  <div key={`content-${index}`}>{content}</div>
                ))}
              </div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};
export default CarouselSlider;
