import { FC, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import AutoHideAlert from "../alert/AutoHideAlert";
import { RevitMartContactFormService } from "../../services/RevitMartContactFormService";
import { IFormData } from "../../interfaces/FormData";
import { Captcha } from "../recaptcha/Recaptcha";
import "./EnquiryForm.style.scss";

const EnquiryForm: FC = () => {
  const initialFormData: IFormData = {
    source: "contact-us",
    name: "",
    email: "",
    phone: "",
    message: "",
  };
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState<IFormData>(initialFormData);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [captchaError, setCaptchaError] = useState<string>();
  const [captchaToken, setCaptchaToken] = useState<string | undefined>(
    undefined
  );

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      if (captchaToken) {
        setIsLoading(true);
        await RevitMartContactFormService(formData)
          .then((response) => {
            if (response) {
              setFormData(initialFormData);
              setSuccessMessage(
                "Your message has been sent and someone will be in touch with you shortly"
              );
              setIsLoading(false);
              setValidated(false);
              setCaptchaToken(undefined);
            }
          })
          .catch((error) => {
            console.error("Error in form submission:", error);
            setIsLoading(false);
            setErrorMessage("Failed to send message.");
          });
      } else {
        setCaptchaError("Please confirm that you are not a robot.");
      }
    }
  };

  return (
    <div className="enquiry-form">
      <Row className="g-0">
        <Col xs={12} md={8}>
          <div className="content">
            <div className="heading mb-3">Enquiry Form</div>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Control
                type="text"
                name="source"
                value="revitmart"
                readOnly
                hidden
              />
              <Row className="g-3 mb-3">
                <Col xs={12} md={10}>
                  <Form.Control
                    type="text"
                    name="name"
                    required
                    placeholder="Name"
                    autoComplete="off"
                    onChange={handleChange}
                    value={formData.name}
                  />
                </Col>
                <Col xs={12} md={10}>
                  <Form.Control
                    type="email"
                    name="email"
                    required
                    placeholder="Email"
                    autoComplete="off"
                    onChange={handleChange}
                    value={formData.email}
                  />
                </Col>
                <Col xs={12} md={8}>
                  <Form.Control
                    type="number"
                    name="phone"
                    required
                    placeholder="Phone"
                    autoComplete="off"
                    onChange={handleChange}
                    value={formData.phone}
                  />
                </Col>
                <Col xs={12} md={10}>
                  <Form.Control
                    as="textarea"
                    name="message"
                    required
                    rows={4}
                    autoComplete="off"
                    placeholder="Message"
                    onChange={handleChange}
                    value={formData.message}
                  />
                </Col>
                <Col xs={12}>
                  <Captcha
                    error={captchaError}
                    captchaToken={captchaToken}
                    setCaptchaToken={setCaptchaToken}
                    setCaptchaError={setCaptchaError}
                  />
                </Col>
                <Col xs={12} md={10}>
                  <Button type="submit" variant="dark" disabled={isLoading}>
                    {isLoading ? "Sending" : "Send"}
                  </Button>
                </Col>
                <Col xs={12} md={10}>
                  {successMessage && (
                    <AutoHideAlert
                      variant="success"
                      message={successMessage}
                      duration={3000}
                      setMessage={setSuccessMessage}
                    />
                  )}
                  {errorMessage && (
                    <AutoHideAlert
                      variant="danger"
                      message={errorMessage}
                      duration={3000}
                      setMessage={setErrorMessage}
                    />
                  )}
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
        <Col xs={12} md={4}>
          <div className="content">
            <Row className="g-3">
              <Col xs={12}>
                <h3 className="heading">Contact Info</h3>
              </Col>
              <Col xs={12} className="mt-0">
                <p className="mb-0">
                  For any questions regarding AutoCAD Drafting, Revit Drafting
                  at RevitMart and it’s affiliations, please Contact us at:
                </p>
              </Col>
              <Col xs={12}>
                <Row>
                  <Col xs={12}>
                    <strong>Address:</strong>
                    <br />
                    3022 Javier Rd,
                    <br />
                    Fairfax, Virginia 22031
                  </Col>
                  <Col xs={12}>
                    <strong>Toll Free : </strong>202-780-7004
                  </Col>
                  <Col xs={12}>
                    <strong>E-Mail :</strong> Info@RevitMart.com
                  </Col>
                  <Col xs={12}>
                    <strong>Hours of operation:</strong> Mon-Fri, 8AM – 5PM EST
                  </Col>
                </Row>
              </Col>
              <Col xs={12}>
                <p>
                  We now have offices in Virginia, Portland and New York with
                  full time staff of 15+ to handle all your needs!
                </p>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default EnquiryForm;
