import { FC } from "react";
import {
  DWG,
  PDF,
  Photos,
  RevitLogo1,
  RevitMartSurvay,
} from "../../assets/images";
import { Col, Row } from "react-bootstrap";
import "./RevitAsBuilts.style.scss";

const RevitAsBuilts: FC = () => {
  return (
    <div className="revit-as-built">
      <Row className="g-0">
        <Col xs={12}>
          <div className="content">
            <p>
              We measure buildings anywhere in the United States! Our site
              technicians use high-accuracy laser distance meters and mobile CAD
              workstations to develop as-built floor plans, ceiling plans,
              elevations, and 3D BIM models. For complicated or hard-to-reach
              spaces, we utilize 3D Laser Scanners. You get accurate, as-built
              plans in Revit, Adobe PDF and AutoCAD DWG format.
            </p>
            <p>
              If you have a preference, we will use custom content provided by
              you to use in our Revit model. We also input all parametric
              information in the families and the Revit Model and deliver it
              along with a shared parameter file that can be imported easily to
              your shared parameter list.
            </p>
            <Row className="g-0">
              <Col xs={12} lg={3}>
                <img
                  src={RevitMartSurvay}
                  alt="RevitMart Survay"
                  className="img-fluid"
                />
              </Col>
              <Col xs={12} lg={9}>
                <h1 className="heading">Laser Measured Plans</h1>
                <p className="text-justify">
                  We measure buildings anywhere in the United States even though
                  our major clients are on the East Coast. To maintain our
                  standards, all our survey technicians have a minimum of 10
                  years experience in the industry and are highly trained. We
                  use high-accuracy laser distance meters and mobile drafting
                  workstations to develop as-built floor plans, ceiling plans,
                  elevations, and 3D BIM models. For complicated or
                  hard-to-reach spaces, we utilize 3D Laser Scanning technology.
                  We deliver accurate, as-built plans in Revit, Adobe PDF and
                  AutoCAD DWG formats. We follow strict standards and thrive to
                  provide accuracy and good service to our clients.
                </p>
              </Col>
            </Row>
          </div>
          <hr />
          <div className="clear-frontrunner">
            <Row className="g-0">
              <Col xs={12}>
                <Row className="g-0">
                  <Col xs={12} md={5} className="clear-frontrunner-content">
                    <h2 className="clear-frontrunner-title">
                      <strong>Clear Frontrunners</strong>
                    </h2>
                    <p className="text-justify">
                      Our state of the art building measurement service is for
                      Property Owners & Managers, Building Operators, Brokers,
                      Architects, Engineers, Designers, Planners, Home Owners,
                      and Tenants. Our plans are used for Aquisition Due
                      Diligence, Lease Area Certification, Area Dispute
                      Resolution, Emergency Procedures Documentation, Marketing,
                      Facility Management, CAFM Integration, LEED Application,
                      Building Retrofit and Permiting. RevitMart has developed
                      our own proprietary softwares that communicates wirelessly
                      with our measuring equipment and updates in our system
                      automatically to avoid human error.
                    </p>
                  </Col>
                  <Col xs={12} md={7} className="py-5 py-md-0">&nbsp;</Col>
                </Row>
              </Col>
            </Row>
          </div>
          <Row className="g-0 content explain">
            <Col xs={12} lg={6} className="px-4">
              <h2 className="explain-title mb-4">
                <strong>What You Get…</strong>
              </h2>

              <Row>
                <Col xs={12} className="d-flex align-items-start">
                  <div className="me-3">
                    <img src={DWG} alt="DWG" width={64} height={64} />
                  </div>
                  <div>
                    <h3 className="explain-subtitle mb-3">AutoCAD DWG Plans</h3>
                    <p>
                      For use by architects, engineers, designers, planners, and
                      technicians. CAD files are supplied as AutoCAD DWG files.
                      All elements are fully layered and follow international
                      standards.
                    </p>
                  </div>
                </Col>
                <Col xs={12} className="d-flex align-items-start">
                  <div className="me-3">
                    <img src={PDF} alt="PDF" width={64} height={64} />
                  </div>
                  <div>
                    <h3 className="explain-subtitle mb-3">
                      Adobe Acrobat PDF Plans
                    </h3>
                    <p>
                      For use by administration and non-technical staff. PDF
                      floor plans are easily viewed and printed using the freely
                      distributed Adobe Acrobat Reader. CAD experience is not
                      required and plans are easy to distribute by email or web.
                    </p>
                  </div>
                </Col>
                <Col xs={12} className="d-flex align-items-start">
                  <div className="me-3">
                    <img
                      src={RevitLogo1}
                      alt="RevitLogo"
                      width={64}
                      height={64}
                    />
                  </div>
                  <div>
                    <h3 className="explain-subtitle mb-3">Revit rvt Models</h3>
                    <p>
                      For use by architects, engineers, designers, planners, and
                      technicians, Revit files are supplied. All elements are
                      3D, real families are used and we follow international
                      standards.
                    </p>
                  </div>
                </Col>
                <Col xs={12} className="d-flex align-items-start">
                  <div className="me-3">
                    <img src={Photos} alt="Photos" width={64} height={64} />
                  </div>
                  <div>
                    <h3 className="explain-subtitle mb-3">
                      Exterior Digital Photographs
                    </h3>
                    <p>
                      Building photographs are supplied as high resolution JPEG
                      files.
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={12} lg={6} className="px-4">
              <h2 className="explain-title mb-4">What We Capture…</h2>
              <Row>
                <Col xs={12}>
                  <h3 className="explain-subtitle mb-3">
                    Our Standard Service Includes
                  </h3>
                  <ul>
                    <li className="my-1">Visible Base Building Construction</li>
                    <li className="my-1">
                      All Demising Walls & Partition Walls
                    </li>
                    <li className="my-1">Doors & Windows</li>
                    <li className="my-1">Stairs, Elevators & Escalators</li>
                    <li className="my-1">Built-in Cabinetry & Millwork</li>
                    <li className="my-1">Major Plumbing Fixtures</li>
                    <li className="my-1">Room Names, Numbers & Labeling</li>
                    <li className="my-1">Location Map & Key Plan</li>
                  </ul>
                </Col>
                <Col xs={12}>
                  <h3 className="explain-subtitle mb-3">Floor Plan Extras</h3>
                  <ul>
                    <li className="my-1">Reflected Ceiling Plan (RCP)</li>
                    <li className="my-1">HVAC</li>
                    <li className="my-1">Fire Plan Details</li>
                    <li className="my-1">Electrical, Tel & Data Fixtures</li>
                    <li className="my-1">Visible Mechanical Elements</li>
                    <li className="my-1">Door, Sill & Header Heights</li>
                    <li className="my-1">Furniture & Equipment</li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
export default RevitAsBuilts;
