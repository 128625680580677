import { FC } from "react";
import { Col, Row } from "react-bootstrap";
import { AdminLogo } from "../../../assets/images";

const WhatsNewDraftingRevitDrawings: FC = () => {
  return (
    <Row className="g-5">
      <Col xs={12}>
        <div className="title">
          <h1 className="heading">
            What’s new in the drafting and AutoCAD Drawing?
          </h1>
        </div>

        <p>What’s new in the drafting and AutoCAD Drawing?</p>

        <p>
          One of them is the development of a computer program, which is at the
          end of using 2D AutoCAD to create high / 3D engine plan and build 3D
          models in the construction of buildings or products by
          <strong> 3D Drafting Company. </strong> Program is very useful service
          and facilitate to operation of high-end services between 2D Drafting
          AutoCAD 29, so I was sent to the improvements so far. The last is the
          fact that it is also known as AutoCAD 2015 AutoCAD 20.0 Administrator
          rhoncus tincidunt ipsum AutoCAD 2014 and then in the past, there is no
          Latin literature in a few words we will see what needs to be done. in
          the preparation and restoration of large and small to improve AutoCAD
          2015 Notes.
        </p>

        <p>
          Lasso tool elections – AutoCAD 2015 with “rope to choose from. The new
          tools provide greater flexibility in the selection of options. If you
          want to wish my readers, click on the left mouse button and drag to
          use his only relationship with objects.
        </p>

        <p>
          If you want to work to bring the mouse then you are willing to select
          the appropriate window of the selected object. Reading the conclusion
          of the curve is selected, and the color is blue and in a timely
          manner. Choosing of work from the left mouse button and drag to select
          objects with curved objects in the border area of green and beds. If
          you want to cancel a function to select and tired of you, and they are
          required to AutoCAD reads the card by
          <strong> 3D Drafting Company. </strong>
        </p>

        <p>
          <strong>Improved annotation</strong>
        </p>

        <p>
          About optimization mile -MTEXT editorial writer in 2015. AutoCAD
          updated version of something. Auto-hide MTEXT editing created in
          AutoCAD dialog box is displayed. To edit the number of objects cars
          ammunition. Now to resize mile editor diagonal. In previous versions
          of AutoCAD, users can adjust the size by adjusting around her. But in
          a text editor here’s the dilemma the text on the cover.
        </p>

        <p>
          Oh text new command – AutoCAD are the commandments, which is the only
          <strong> 3D Drafting Company </strong> allows the user to arrange. In
          many places, the corridor in one line and many agencies are involved
          in the quoted text. Use this space to set the left and right of the
          central body and so on
        </p>

        <p>
          The geographical location is updated tools – tools of AutoCAD 2014
          enables users to determine the geographical location of the project
          plan is not the place, but users of AutoCAD 2015 to overcome the type,
          you can use AutoCAD. The place is a new tool in the world for you in
          this image taken by the image of loss of life. Now is the time, may be
          more effective at 360, if you want, even need to use any other signed.
        </p>

        <p>
          If AutoCAD 2015 makes minor improvements is preparation better. But it
          still gives you a better output than the previous model, the new
          command-line tool text two Tesla has been updated AutoCAD outsourcing
          services experts with 10+ years of experience in the use of CAD
          development. Drawings, etc., themselves
        </p>

        <p>
          <i className="fa fa-tag" /> 3D Drafting Company, 3D Drafting Services,
          AutoCAD Revit, CAD Drafting, CAD Drafting Services, Engineering
          Drafting Services, MEP Drafting Services, Revit Company, Revit
          Drafting, Revit Drafting Service, Revit Services
        </p>
      </Col>
      <Col xs={12}>
        <div className="entry-author">
          <img src={AdminLogo} alt="admin" />
          <p className="writer-name">
            Written by <span className="text-dark">admin</span>
          </p>
        </div>
      </Col>
    </Row>
  );
};
export default WhatsNewDraftingRevitDrawings;
