import { FC } from "react";
import {
  DraftingService1,
  DraftingService2,
  MepDraftingService,
  RevitMep1,
  RevitMep2,
} from "../../assets/images";
import { Col, Row } from "react-bootstrap";

const RevitMep: FC = () => {
  return (
    <Row className="g-0">
      <Col xs={12} className="divider">
        <div className="content">
          <p>
            We provide professional 2D and 3D CAD Revit MEP Drafting services to
            Architects, Engineers, Manufacturers and contractors within the
            building services sector.
          </p>

          <p>
            Working with mechanical, electrical and plumbing design engineers,
            consultants and contractors, we convert as-builts and marked-up
            documents or CAD files into Revit models. Over the years, we have
            satisfied the needs of many engineering firms in Washington DC and
            New York. We work with many equipment manufacturers to develop their
            BIM content and give our clients the benefit of having access to all
            out developed material and Trademark softwares.
          </p>

          <p>
            <strong>Our experience spans a range of sectors, including:</strong>
          </p>

          <p>
            <i className="bi bi-check-lg"></i> High Rise Office Buildings
            <br />
            <i className="bi bi-check-lg"></i> High Rise Condos
            <br />
            <i className="bi bi-check-lg"></i> Large Footprint Commercial
            Buildings
            <br />
            <i className="bi bi-check-lg"></i> Shopping Malls
            <br />
            <i className="bi bi-check-lg"></i> HealthCare Facilities and
            Hospitals
            <br />
            <i className="bi bi-check-lg"></i> Laboratories
            <br />
            <i className="bi bi-check-lg"></i> Complex Central Plants
          </p>

          <p>
            We utilise the latest technology to communicate and share files
            using web-based online collaboration systems and sophisticated
            mark-up tools.
          </p>

          <p>
            <strong>
              As a part of our MEP drafting services, we provide the following:
            </strong>
          </p>

          <p>
            <i className="bi bi-check-lg"></i> Schematic MEP Drawings
            <br />
            <i className="bi bi-check-lg"></i> Design Documents for MEP
            <br />
            <i className="bi bi-check-lg"></i> Construction Drawings for MEP
            <br />
            <i className="bi bi-check-lg"></i> As-built MEP Drawings
            <br />
            <i className="bi bi-check-lg"></i> Ensure that there are no clashes
            (this can be validated easily using clash detection software tools
            like Navisworks)
            <br />
            <i className="bi bi-check-lg"></i> By using the latest BIM modelling
            softwares for coordination, we are able to verify that all trades
            are fully coordinated
            <br />
            <i className="bi bi-check-lg"></i> Creating Revit® Families That
            Look 99.9% Real
            <br />
            <i className="bi bi-check-lg"></i> Detailed Energy Modelling using
            BIM
            <br />
            <i className="bi bi-check-lg"></i> Converting 2D Files Into
            Informative 3D Content
            <br />
            <i className="bi bi-check-lg"></i> Converting 2D AutoCAD® Drawings
            Into Real Time Intelligent 3D Systems
            <br />
            <i className="bi bi-check-lg"></i> Making Revit® Families In
            Accordance With The Various Engineering Code Standards
            <br />
            <i className="bi bi-check-lg"></i> Making Company Templates
            <br />
            <i className="bi bi-check-lg"></i> Specialized Rendering Services &
            Walkthrough Movies
            <br />
            <i className="bi bi-check-lg"></i> Modifying Current 3D Content As
            Per Your Needs/Company Standards
            <br />
            <i className="bi bi-check-lg"></i>Checking Your Deliverable Revit®
            Model For Any Inconsistencies Amongst Its Systems throughout the
            project
          </p>

          <p>
            PS: ‘RevitMart’ is a place where you can find everything you need
            related to your Revit® needs!
          </p>
          <Row>
            <Col xs={12}>
              <img
                className="img-fluid"
                src={RevitMep1}
                alt="Engineering 3D Revit MEP Drafting Services"
                title="Engineering 3D Revit MEP Drafting Services"
              />
            </Col>
            <Col xs={12}>
              <img
                className="img-fluid"
                src={MepDraftingService}
                alt="Engineering 3D Revit MEP Drafting Services"
                title="Engineering 3D Revit MEP Drafting Services"
              />
            </Col>
            <Col xs={12}>
              <img
                className="img-fluid"
                src={DraftingService1}
                alt="Engineering 3D Revit MEP Drafting Services Revit Drafting"
                title="Engineering 3D Revit MEP Drafting Services Revit Drafting"
              />
            </Col>
            <Col xs={12}>
              <img
                className="img-fluid"
                src={DraftingService2}
                alt="Engineering 3D Revit MEP Drafting Services Revit Drafting"
                title="Engineering 3D Revit MEP Drafting Services Revit Drafting"
              />
            </Col>
            <Col xs={12}>
              <img
                className="img-fluid"
                src={RevitMep2}
                alt="Engineering 3D Revit MEP Drafting Services"
                title="Engineering 3D Revit MEP Drafting Services"
              />
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};
export default RevitMep;
