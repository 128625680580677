import { FC } from "react";
import { ShopDrawings1, ShopDrawings2 } from "../../assets/images";
import { Col, Row } from "react-bootstrap";

const ShopDrawings: FC = () => {
  return (
    <Row className="g-0">
      <Col xs={12} className="divider">
        <div className="content">
          <p>
            For almost a decade, we have been providing companies like yours
            with shop and coordination drawings that are accurate and
            thoughtfully prepared. We specialize in shop drawings drawings for
            ductwork fabrication, plumbing, HVAC piping, electrical coordination
            and signage. We use the latest Navisworks (BIM Modeling), Revit and
            other Autocad ™ Products plus the latest MacsCad ™ that allows us to
            input information much faster than before which gives us more time
            to research the job, slightly manipulate the drawings to save on
            materials and labor in the field and to double check our work. The
            drawings are done with great detail, accuracy and clarity and the
            turnaround is very quick!
          </p>

          <p>
            We have produced and shipped thousands of HVAC Shop Drawings, Piping
            Shop Drawings, Plumbing Shop Drawings and MEP Coordination Drawings
            for hundreds of jobs just like yours all across the US & Hawaii. We
            also provide MEP Coordination & BIM Coordination Services as well as
            HVAC BIM, Piping BIM, and Plumbing BIM Coordination Services. The
            feedback that we hear most often from engineers, architects, GCs,
            and the trades is…
            <strong className="fst-italic">
              “These are the best shop drawings I have ever seen.”
            </strong>
            Here at RevitMart, we take great care and pride everyday in
            producing professional shop and coordination drawings that are
            precise, legible, detailed and on-time.
          </p>

          <p>
            Our certified drafters and engineers are well trained in shop
            drawing detailing, and have been performing shop drawing services
            successfully for several years. We have experience in collaborating
            and communicating with sub-contractors, vendors and manufacturers to
            produce quality shop drawings, Our shop drawing expertise includes:
          </p>

          <ul>
            <li className="my-1">
              Actual installation heights for ducts, pipes and conduits
            </li>
            <li className="my-1">Changes / alteration notes</li>
            <li className="my-1">Fabrication information</li>
            <li className="my-1">Jobsite dimension verification</li>
            <li className="my-1">Material Takeoffs</li>
            <li className="my-1">MEP coordination</li>
          </ul>

          <Row>
            <Col xs={12}>
              <img
                className="img-fluid"
                src={ShopDrawings1}
                alt="Ductwork Shop Drawings"
                title="Ductwork Shop Drawings"
              />
              <div className="fst-italic">Ductwork Shop Drawings</div>
            </Col>
            <Col xs={12}>
              <img
                className="img-fluid"
                src={ShopDrawings2}
                alt="Ductwork Shop Drawings"
                title="Ductwork Shop Drawings"
              />
              <div className="fst-italic">Ductwork Shop Drawings</div>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};
export default ShopDrawings;
