import { FC, useEffect } from "react";
import MainRoutes from "./components/router/MainRoutes";
import Aos from "aos";
import "aos/dist/aos.css";
import "./App.scss";

const App: FC = () => {
  useEffect(() => {
    Aos.init({
      duration: 1000,
      delay: 100,
      once: true,
    });
  }, []);

  return <MainRoutes />;
};

export default App;
