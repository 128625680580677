import { FC } from "react";
import { Link } from "react-router-dom";
import { PageRoutesConstant } from "../../components/router/components/PageRoutes";
import RequestFrom from "../../components/request-form/RequestFrom";
import { Row, Col } from "react-bootstrap";
import "./Careers.style.scss";

const Careers: FC = () => {
  return (
    <div className="careers">
      <Row className="g-0">
        <Col xs={12} className="divider">
          <div className="content">
            <p className="text-justify">
              Thank you for your interest in becoming a part of RevitMart team!
              We understand the importance of our employees in bringing this
              company up and therefore, we offer very good packages as our
              gratitude to our employees. We are always in seek for talented CAD
              and BIM Drafters. In order to be considered for this position,
              please use this page to give us any possible details about
              yourself and your experience in the industry and we will get back
              to you in a timely manner. Along with filling the form below, we
              encourage you to email us your resume at info@revitmart.com.
            </p>

            <p>
              Please note that we cannot respond to general questions and/or
              information requests submitted through this page and this form
              will not help you receive customer support. For customer support,
              please go to&nbsp;
              <Link
                className="contact-link"
                to={PageRoutesConstant.Page.ContactUs.path}
              >
                Contact us
              </Link>
              &nbsp; page.
            </p>

            <RequestFrom formSource="career-contact" />
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default Careers;
