import axios, { AxiosResponse } from "axios";
import { IPaymentData } from "../interfaces/PaymentData";

declare global {
  interface Window {
    StripeCheckout: any;
  }
}

export const loadStripeScript = (
  name: string,
  description: string,
  amount: number,
  currency: string,
  redirectUrl: string,
  navigate: (path: string) => void,
  onPaymentSuccess: (isSuccess: boolean) => void,
  onPaymentFail: (isError: boolean, error: string) => void
) => {
  if (!document.getElementById("stripe-script")) {
    const script = document.createElement("script");
    script.id = "stripe-script";
    script.src = "https://checkout.stripe.com/checkout.js";
    script.onload = () => {
      initializeStripe(
        name,
        description,
        amount,
        currency,
        redirectUrl,
        navigate,
        onPaymentSuccess,
        onPaymentFail
      );
    };
    document.body.appendChild(script);
  } else {
    initializeStripe(
      name,
      description,
      amount,
      currency,
      redirectUrl,
      navigate,
      onPaymentSuccess,
      onPaymentFail
    );
  }
};

const initializeStripe = (
  name: string,
  description: string,
  amount: number,
  currency: string,
  redirectUrl: string,
  navigate: (path: string) => void,
  onPaymentSuccess: (isSuccess: boolean) => void,
  onPaymentFail: (isError: boolean, error: string) => void
) => {
  if (window.StripeCheckout) {
    const handler = window.StripeCheckout.configure({
      key: process.env.REACT_APP_STRIPE_PUBLISH_KEY,
      locale: "auto",
      currency: currency,
      panelLabel: "Pay Now {{amount}}",
      token: async (stripeToken: any) => {
        if (stripeToken) {
          const payload: IPaymentData = {
            description: description,
            amount: amount,
            currency: currency.toLocaleUpperCase(),
            stripeEmail: stripeToken.email,
            token: stripeToken.id,
          };
          await StripeCheckout(payload)
            .then((response: any) => {
              if (response.data.status === "succeeded") {
                onPaymentSuccess(true);
                setTimeout(() => {
                  navigate(redirectUrl);
                }, 2000);
              }
            })
            .catch((error: any) => {
              onPaymentFail(true, error?.response?.data?.split(';')[0]);
              console.error(error);
            });
        }
      },
    });
    handler.open({
      name: name,
      description: description,
      amount: amount,
    });
  }
};

export const StripeCheckout = async (
  data: IPaymentData
): Promise<AxiosResponse<any>> => {
  try {
    const apiUrl = process.env.REACT_APP_PAYMENT_API_URL;
    if (!apiUrl) throw new Error("API URL is not defined");

    const response = await axios.post(`${apiUrl}/payment-rm`, data);

    return response;
  } catch (error) {
    console.error("Error in RevitMart StripeCheckoutService:", error);
    throw error;
  }
};
