export const socialMediaLinks = [
    { name: "Twitter", url: "https://twitter.com/", icon: "bi bi-twitter" },
    {
      name: "Facebook",
      url: "https://www.facebook.com/revitmart/",
      icon: "bi bi-facebook",
    },
    {
      name: "LinkedIn",
      url: "https://www.linkedin.com/",
      icon: "bi bi-linkedin",
    },
    {
      name: "Google Plus",
      url: "https://plus.google.com/+Revitmart-Revit-Drafting-Services",
      icon: "fab fa-google-plus-g",
    },
    { name: "YouTube", url: "https://www.youtube.com/", icon: "bi bi-youtube" },
  ];