import { FC } from "react";
import {
  LaserScan2,
  LaserScan3,
  LaserScan4,
  LaserScan5,
  LaserScan6,
  LaserScan7,
  LaserScan8,
} from "../../assets/images";
import { Col, Row } from "react-bootstrap";
import "./LaserScan.style.scss";

const LaserScan: FC = () => {
  return (
    <div className="laser-scan">
      <Row className="g-0">
        <Col xs={12}>
          <div className="content">
            <p>
              Laser scan helps creating accurate as-builts like never before.
              You call RevitMart Laser Scan team, we laser scan the building
              with our high tech scanners and issue you an accurate Revit Model.
            </p>

            <p>
              RevitMart delivers coordinated as built model, providing enormous
              time savings and quality improvements in design deliverables. We
              bring the facility back to our office to avoid return site trips.
            </p>

            <p>
              RevitMart uses a wide range of laser scan software packages to
              create the deliverables as accurate as possible. Our patented lase
              scan technology software is valued as a great asset to our
              customers.
            </p>
            <p>
              Having accurate as-builts will save your clients thousands of
              dollards in potential change orders.
            </p>

            <p>
              Having accurate as-builts will also save overall design and
              construction time.
            </p>

            <p>
              Architects and engineers can make critical design decisions sooner
              and with more complete and accurate information. They can also
              creatively utilize the point cloud data directly for construction
              documents, minimizing their drawing or modeling hours. This, in
              turn, results in a reduction of both RFI’s and change orders
              related to field conditions, as well as a reduction in errors and
              omissions.
            </p>

            <p>
              <strong>
                Below are some of the benefits we provide when you use RevitMart
                as your Consultant:
              </strong>
            </p>

            <p>
              <i className="bi bi-check-lg"></i> We deliver Revit Models instead
              of just point clouds
              <br />
              <i className="bi bi-check-lg"></i> Conceptual cost estimating
              <br />
              <i className="bi bi-check-lg"></i>Ability to modify geometry by
              typing a new number, saving time during iterative design processes
              <br />
              <i className="bi bi-check-lg"></i> Integrated building materials
              <br />
              <i className="bi bi-check-lg"></i> Integrated project phasing
              <br />
              <i className="bi bi-check-lg"></i> Integrated project phasing
              <br />
              <i className="bi bi-check-lg"></i> Relationships between
              parametric components
              <br />
              <i className="bi bi-check-lg"></i> Single multiuser building
              database
              <br />
              <i className="bi bi-check-lg"></i> Demolition and construction
              phasing
              <br />
              <i className="bi bi-check-lg"></i> Graphic controls for linked
              Revit models
              <br />
              <i className="bi bi-check-lg"></i> Multiuser worksets
              <br />
              <i className="bi bi-check-lg"></i> On-demand database reading
              <br />
              <i className="bi bi-check-lg"></i> Runtime shared parameters
              <br />
              <i className="bi bi-check-lg"></i> Material take-off for detailed
              cost estimating
              <br />
              <i className="bi bi-check-lg"></i> Extract as much or little
              information as you need, when you need it.
              <br />
              <i className="bi bi-check-lg"></i> Create accurate 2D and 3D
              documentation for architects or engineers to facilitate
              renovations, additions, or re-purposing.
              <br />
              <i className="bi bi-check-lg"></i> Create a Building Information
              Model (BIM) for contractor collaboration, energy studies, facility
              management, and more. models
              <br />
              <i className="bi bi-check-lg"></i> Monitor construction for
              accuracy and create “close out” as-built documentation after each
              trade or phase is finished.
              <br />
              <i className="bi bi-check-lg"></i> Perform off-site prefabrication
              of building components.
              <br />
              <i className="bi bi-check-lg"></i> Monitor structures over time
              for attrition and settling.
            </p>

            <p>
              Laser Scanning is the most cost and time effective method of
              capturing all measurements of the interior and exterior of a
              building in its present state
            </p>
          </div>
        </Col>

        <Col xs={12}>
          <div className="content">
            <Row>
              <Col xs={12} lg={3}>
                <img
                  src={LaserScan2}
                  alt="3dScannerLg"
                  title="Building Laser Scan"
                  className="img-fluid"
                />
              </Col>
              <Col xs={12} lg={9}>
                <h1 className="heading">3D Laser Scanning</h1>
                <p className="text-justify">
                  Our laser scanning service is available across North America.
                  We use next-generation LIDAR scanners capable of
                  high-resolution dome scans. Every scan measures millions of
                  points and each point knows its precise XYZ position in space.
                  Collectively, these points constitute a “point cloud” that can
                  be navigated, drafted, and modeled in many of todays most
                  popular CAD applications. Compared to conventional surveys,
                  laser scanning captures environments in astonishing detail.
                  Even the most challenging geometry such as curves, angles, and
                  deformations can be scanned with unprecedented accuracy.
                </p>
              </Col>
            </Row>
          </div>
        </Col>

        <Col xs={12}>
          <div className="content text-center">
            <img
              src={LaserScan3}
              alt="RevitMart-Laser Scan"
              title="RevitMart-Laser Scan"
              className="img-fluid"
              width={600}
              height={397}
            />
            <img
              src={LaserScan4}
              alt="RevitMart-Laser Scan"
              title="RevitMart-Laser Scan"
              className="img-fluid"
              width={960}
              height={350}
            />
            <img
              src={LaserScan5}
              alt="RevitMart-Laser Scan"
              title="RevitMart-Laser Scan"
              className="img-fluid"
              width={698}
              height={400}
            />
          </div>
        </Col>

        <Col xs={12} className="scan-to-cad">
          <div className="content">
            <h1 className="title">Scan to CAD</h1>
            <Row className="justify-content-center">
              <Col xs={10}>
                <img
                  className="img-fluid"
                  src={LaserScan6}
                  alt="RevitMart-Scan-to-CAD"
                  title="RevitMart-Scan-to-CAD"
                />
                <div className="fst-italic mb-3">
                  RevitMart-Laser Scan-to-CAD
                </div>
                <h3 className="description">
                  We extract floor plans, elevations, sections, TIN meshes &
                  solid models directly from point clouds.
                </h3>
              </Col>
            </Row>
          </div>
        </Col>

        <Col xs={12} className="scan-to-bim">
          <div className="content">
            <h1 className="title">Scan to BIM</h1>
            <Row className="justify-content-center">
              <Col xs={10}>
                <img
                  className="img-fluid"
                  src={LaserScan7}
                  alt="scanToBimLg"
                  title="Building Laser Scan"
                />
                <div className="fst-italic mb-3">Building Laser Scan</div>
                <h3 className="description">
                  We generate Building Information Models directly from 3D scan
                  data in Revit and IFC formats.
                </h3>
              </Col>
            </Row>
          </div>
        </Col>

        <Col xs={12}>
          <div className="content">
            <Row>
              <Col xs={12} lg={3}>
                <img
                  className="img-fluid"
                  src={LaserScan8}
                  alt="3dScannerLg"
                  title="Building Laser Scan"
                />
              </Col>
              <Col xs={12} lg={9}>
                <h1 className="heading">Native Application Support</h1>
                <p className="text-justify">
                  Point clouds can be imported into the latest versions of
                  AutoCAD, Revit, Civil3D, Inventor, Navisworks, Bentley
                  Microstation, and many other applications. Architects,
                  engineers, and technicians can now manipulate, draft, and
                  model scan data right from their favorite CAD software. We
                  deliver PCG, PTX, or FLS files, and additional formats are
                  available upon request.
                </p>
              </Col>
            </Row>
          </div>
        </Col>

        <Col xs={12}>
          <div className="content">
            <Row>
              <Col xs={12} lg={8}>
                <h1 className="heading">Buildings and More…</h1>
                <p className="text-justify">
                  Applied to architecture, a point cloud is nothing short of a
                  dimensionally-correct, digital reproduction of the building
                  itself. Point clouds can be referenced for a myriad of
                  practical uses, including the generation of floor plans, site
                  plans, elevations, ceiling plans, mechanical layouts,
                  inventories, and Building Information Models (BIM).
                </p>
                <p className="text-justify">
                  Laser scanning also has interesting applications in
                  manufacturing, aerospace, mining, film, gaming, archaeology,
                  reverse-engineering, forensics, and accident reconstruction
                  (to name a few) and novel utilizations of the technology are
                  being discovered all the time. If you think 3D laser scanning
                  may be a good fit for your application, let’s talk.
                </p>
              </Col>
              <Col xs={12} lg={4}>
                <h1 className="heading">Use Cases</h1>
                <ul>
                  <li className="my-1">Buildings & Architecture</li>
                  <li className="my-1">Construction Sites</li>
                  <li className="my-1">Historic Preservation</li>
                  <li className="my-1">Reverse Engineering</li>
                  <li className="my-1">Custom Installs & Fabrication</li>
                  <li className="my-1">Factories & Manufacturing</li>
                  <li className="my-1">Bridges, Tunnels, & Civic Projects</li>
                  <li className="my-1">Street Scapes & Topography</li>
                  <li className="my-1">Surface & Sub-Surface Mining</li>
                  <li className="my-1">
                    Crime Scene & Accident Reconstruction
                  </li>
                  <li className="my-1">Film, Gaming, & Entertainment</li>
                </ul>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default LaserScan;
