import { FC, useEffect, useState } from "react";
import MainFooter from "../../footer/MainFooter";
import MainHeader from "../../header/MainHeader";
import MainNavbar from "../../navbar/MainNavbar";
import BlogRoutes, { BlogRoutesConstant } from "./BlogRoutes";
import { Col, Row } from "react-bootstrap";
import BlogHeader from "../../blog-header/BlogHeader";
import Breadcrumbs from "../../breadcrumbs/Breadcrumbs";
import Contact from "../../contact-form/ContactForm";
import { useLocation } from "react-router-dom";
import { PageRoutesConstant } from "./PageRoutes";

const BlogFlowRoutes: FC = () => {
  const [currentBlogName, setCurrentBlogName] = useState<string>();
  const location = useLocation();

  useEffect(() => {
    const key = Object.keys(BlogRoutesConstant.Blog).find(
      (item) => BlogRoutesConstant.Blog[item].path === location.pathname
    );
    if (key) {
      setCurrentBlogName(BlogRoutesConstant.Blog[key].title);
    }
  }, [location.pathname]);

  return (
    <div className="main">
      <div className="layout container-md">
        <header>
          <MainHeader />
          <MainNavbar />
        </header>
        <div className="pages">
          <div className="blog-detail">
            <Row className="g-0">
              <Col xs={12}>
                <BlogHeader headerText="RevitMart Blog" />
              </Col>
              <Col xs={12}>
                {location.pathname !== BlogRoutesConstant.Blog.Home.path ? (
                  <Breadcrumbs
                    blog={{
                      name: "RevitMart Blog",
                      path: PageRoutesConstant.Page.Blog.path,
                    }}
                    currentPageName={currentBlogName}
                  />
                ) : (
                  <Breadcrumbs currentPageName="RevitMart Blog" />
                )}
              </Col>
              <Col xs={12}>
                <div className="content">
                  <BlogRoutes />
                </div>
              </Col>
              {location.pathname !== BlogRoutesConstant.Blog.Home.path && (
                <Col xs={12} md={6}>
                  <div className="content">
                    <Contact />
                  </div>
                </Col>
              )}
            </Row>
          </div>
        </div>
        <footer>
          <MainFooter />
        </footer>
      </div>
    </div>
  );
};

export default BlogFlowRoutes;
