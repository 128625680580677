import { Route, Routes } from "react-router-dom";
import { PageRoutesConstant } from "./PageRoutes";
import Blog from "../../../pages/blogs/Blog";
import TheRevitAdvantage from "../../../pages/blogs/the-revit-advantage/TheRevitAdvantage";
import EffectivenessCadDraftingMepCoordinationProjectsTwo from "../../../pages/blogs/effectiveness-cad-drafting-mep-coordination-projects-two/EffectivenessCadDraftingMepCoordinationProjectsTwo";
import RevitUseStructuralAnalysisTwo from "../../../pages/blogs/revit-use-structural-analysis-two/RevitUseStructuralAnalysisTwo";
import CensurablenessCadSoftwareEngineeringProcessSharpe from "../../../pages/blogs/censurableness-cad-software-engineering-process-sharpe/CensurablenessCadSoftwareEngineeringProcessSharpe";
import MilitaryInfluentialDesignServicesAccurateRepresentation from "../../../pages/blogs/military-influential-design-services-accurate-representation/MilitaryInfluentialDesignServicesAccurateRepresentation";
import PotentialOptionsAvailableExactDesign from "../../../pages/blogs/potential-options-available-exact-design/PotentialOptionsAvailableExactDesign";
import RepresentativesServicesInfluentialDesignClear from "../../../pages/blogs/representatives-services-influential-design-clear/RepresentativesServicesInfluentialDesignClear";
import StructuralInventionDraftingThreedModelingServices from "../../../pages/blogs/structural-invention-drafting-3d-modeling-services/StructuralInventionDraftingThreedModelingServices";
import WhatsNewDraftingRevitDrawings from "../../../pages/blogs/whats-new-drafting-revit-drawings/WhatsNewDraftingRevitDrawings";
import RevitmartCadDraftingServices from "../../../pages/blogs/revitmart-cad-drafting-services/RevitmartCadDraftingServices";
import RevitmartMepDraftingServicesAffordableReliable from "../../../pages/blogs/revitmart-mep-drafting-services-affordable-reliable/RevitmartMepDraftingServicesAffordableReliable";
import SurveyCompanySpeedBuiltSurveys from "../../../pages/blogs/survey-company-speed-built-surveys/SurveyCompanySpeedBuiltSurveys";
import RevitSimulationArchitecturalProjects from "../../../pages/blogs/revit-simulation-architectural-projects/RevitSimulationArchitecturalProjects";
import EffectivenessCadDraftingMepCoordinationProjects from "../../../pages/blogs/effectiveness-cad-drafting-mep-coordination-projects/EffectivenessCadDraftingMepCoordinationProjects";
import RevitImpactDesign from "../../../pages/blogs/revit-impact-design/RevitImpactDesign";
import RevitUseStructuralAnalysis from "../../../pages/blogs/revit-use-structural-analysis/RevitUseStructuralAnalysis";
import BenefitsOfBimInDesign from "../../../pages/blogs/benefits-of-bim-in-design/BenefitsOfBimInDesign";
import AutodeskRevitArchitecture from "../../../pages/blogs/autodesk-revit-architecture/AutodeskRevitArchitecture";
import BimDesignSolutions from "../../../pages/blogs/bim-design-solutions/BimDesignSolutions";
import RevitForRetail from "../../../pages/blogs/revit-for-retail/RevitForRetail";
import MepDraftingCadRevit from "../../../pages/blogs/mep-drafting-cad-revit/MepDraftingCadRevit";
import InsightMepDraftingServices from "../../../pages/blogs/insight-mep-drafting-services/InsightMepDraftingServices";
import AutocadDraftingServicesComputerAidedDesign from "../../../pages/blogs/autocad-drafting-services-computer-aided-design/AutocadDraftingServicesComputerAidedDesign";
import CadSoftwareValue from "../../../pages/blogs/cad-software-value/CadSoftwareValue";
import ComprehensiveAnalyzingPowerMechanicalCadDrafting from "../../../pages/blogs/comprehensive-analyzing-power-mechanical-cad-drafting/ComprehensiveAnalyzingPowerMechanicalCadDrafting";
import ConsideredOutsourcingRevitDrafting from "../../../pages/blogs/considered-outsourcing-revit-drafting/ConsideredOutsourcingRevitDrafting";
import AutodeskRevitArchitectureMeanNeed from "../../../pages/blogs/autodesk-revit-architecture-mean-need/AutodeskRevitArchitectureMeanNeed";
import BenefitsBimDesignImplementation from "../../../pages/blogs/benefits-bim-design-implementation/BenefitsBimDesignImplementation";
import ChooseBimDesignSolutions from "../../../pages/blogs/choose-bim-design-solutions/ChooseBimDesignSolutions";
import RevitComprehensiveOverviewPartTwo from "../../../pages/blogs/revit-comprehensive-overview-part-two/RevitComprehensiveOverviewPartTwo";
import UseRevitDraftingRetailSector from "../../../pages/blogs/use-revit-drafting-retail-sector/UseRevitDraftingRetailSector";
import SimulationArchitecturalProjectsImportantAspectsRevitServices from "../../../pages/blogs/simulation-architectural-projects-important-aspects-revit-services/SimulationArchitecturalProjectsImportantAspectsRevitServices";
import MakeDreamHomeSketchesReality from "../../../pages/blogs/make-dream-home-sketches-reality/MakeDreamHomeSketchesReality";
import RevitDraftingCuttingEdgeBuildingIndustry from "../../../pages/blogs/revit-drafting-cutting-edge-building-industry/RevitDraftingCuttingEdgeBuildingIndustry";
import UsingRevitRetailDesign from "../../../pages/blogs/using-revit-retail-design/UsingRevitRetailDesign";
import OutsourcingThreedDraftingServices from "../../../pages/blogs/outsourcing-threed-drafting-services/OutsourcingThreedDraftingServices";
import WantReplicatingBuildingBegin from "../../../pages/blogs/want-replicating-building-begin/WantReplicatingBuildingBegin";
import RevitComprehensiveOverviewPartThree from "../../../pages/blogs/revit-comprehensive-overview-part-three/RevitComprehensiveOverviewPartThree";
import RevitImpactDesignTwo from "../../../pages/blogs/revit-impact-design-two/RevitImpactDesignTwo";
import OutsourcingDraftingBenefits from "../../../pages/blogs/outsourcing-drafting-benefits/OutsourcingDraftingBenefits";
import ThreeDDraftingServices from "../../../pages/blogs/threed-drafting-services/ThreeDDraftingServices";
import ThreeDDraftingCompany from "../../../pages/blogs/threed-drafting-company/ThreeDDraftingCompany";
import { IRouteKey } from "../../../interfaces/RouteKey";


interface IRoute {
  Blog: IRouteKey;
}

export const BlogRoutesConstant: IRoute = {
  Blog: {
    Home: {
      path: `${PageRoutesConstant.Page.Blog.path}`,
      element: <Blog />,
    },
    TheRevitAdvantage: {
      title: "The Revit advantage",
      path: `${PageRoutesConstant.Page.Blog.path}/the-revit-advantage`,
      element: <TheRevitAdvantage />,
    },
    EffectivenessCadDraftingMepCoordinationProjectsTwo: {
      title:
        "Effectiveness of CAD Drafting for MEP Coordination Projects. Part 2",
      path: `${PageRoutesConstant.Page.Blog.path}/effectiveness-cad-drafting-mep-coordination-projects-2`,
      element: <EffectivenessCadDraftingMepCoordinationProjectsTwo />,
    },
    EffectivenessCadDraftingMepCoordinationProjects: {
      title: "Effectiveness of CAD Drafting for MEP Coordination Projects",
      path: `${PageRoutesConstant.Page.Blog.path}/effectiveness-cad-drafting-mep-coordination-projects`,
      element: <EffectivenessCadDraftingMepCoordinationProjects />,
    },
    RevitUseStructuralAnalysisTwo: {
      title: "Revit and its Use in Structural Analysis",
      path: `${PageRoutesConstant.Page.Blog.path}/revit-use-structural-analysis-2`,
      element: <RevitUseStructuralAnalysisTwo />,
    },
    CensurablenessCadSoftwareEngineeringProcessSharpe: {
      title: "Censurableness of CAD software engineering process Sharpe",
      path: `${PageRoutesConstant.Page.Blog.path}/censurableness-cad-software-engineering-process-sharpe`,
      element: <CensurablenessCadSoftwareEngineeringProcessSharpe />,
    },
    MilitaryInfluentialDesignServicesAccurateRepresentation: {
      title: "Military influential design services for accurate representation",
      path: `${PageRoutesConstant.Page.Blog.path}/military-influential-design-services-accurate-representation`,
      element: <MilitaryInfluentialDesignServicesAccurateRepresentation />,
    },
    PotentialOptionsAvailableExactDesign: {
      title: "Potential options available for the exact design",
      path: `${PageRoutesConstant.Page.Blog.path}/potential-options-available-exact-design`,
      element: <PotentialOptionsAvailableExactDesign />,
    },
    RepresentativesServicesInfluentialDesignClear: {
      title: "Representatives of the services of influential design with clear",
      path: `${PageRoutesConstant.Page.Blog.path}/representatives-services-influential-design-clear`,
      element: <RepresentativesServicesInfluentialDesignClear />,
    },
    StructuralInventionDraftingThreedModelingServices: {
      title: "Structural Invention Drafting and 3D Modeling Services",
      path: `${PageRoutesConstant.Page.Blog.path}/structural-invention-drafting-3d-modeling-services`,
      element: <StructuralInventionDraftingThreedModelingServices />,
    },
    WhatsNewDraftingRevitDrawings: {
      title: "What’s new in the drafting and Revit Drawings?",
      path: `${PageRoutesConstant.Page.Blog.path}/whats-new-drafting-revit-drawings`,
      element: <WhatsNewDraftingRevitDrawings />,
    },
    RevitmartCadDraftingServices: {
      title: "RevitMart CAD Drafting Services",
      path: `${PageRoutesConstant.Page.Blog.path}/revitmart-cad-drafting-services`,
      element: <RevitmartCadDraftingServices />,
    },
    ThreeDDraftingServices: {
      title: "3D Drafting Services",
      path: `${PageRoutesConstant.Page.Blog.path}/3d-drafting-services`,
      element: <ThreeDDraftingServices />,
    },
    ThreeDDraftingCompany: {
      title: "3D Drafting Company",
      path: `${PageRoutesConstant.Page.Blog.path}/3d-drafting-company`,
      element: <ThreeDDraftingCompany />,
    },
    RevitmartMepDraftingServicesAffordableReliable: {
      title: "RevitMart MEP Drafting Services: Affordable and Reliable",
      path: `${PageRoutesConstant.Page.Blog.path}/revitmart-mep-drafting-services-affordable-reliable`,
      element: <RevitmartMepDraftingServicesAffordableReliable />,
    },
    SurveyCompanySpeedBuiltSurveys: {
      title: "Survey Company: How to Speed up As-built Surveys",
      path: `${PageRoutesConstant.Page.Blog.path}/survey-company-speed-built-surveys`,
      element: <SurveyCompanySpeedBuiltSurveys />,
    },
    RevitSimulationArchitecturalProjects: {
      title: "Revit Simulation of Architectural Projects",
      path: `${PageRoutesConstant.Page.Blog.path}/revit-simulation-architectural-projects`,
      element: <RevitSimulationArchitecturalProjects />,
    },
    RevitImpactDesign: {
      title: "How Does Revit Impact Design",
      path: `${PageRoutesConstant.Page.Blog.path}/revit-impact-design`,
      element: <RevitImpactDesign />,
    },
    RevitUseStructuralAnalysis: {
      title: "Revit and its Use in Structural Analysis",
      path: `${PageRoutesConstant.Page.Blog.path}/revit-use-structural-analysis`,
      element: <RevitUseStructuralAnalysis />,
    },
    BenefitsOfBimInDesign: {
      title: "Benefits of BIM in Design and Implementation",
      path: `${PageRoutesConstant.Page.Blog.path}/benefits-of-bim-in-design`,
      element: <BenefitsOfBimInDesign />,
    },
    AutodeskRevitArchitecture: {
      title:
        "Autodesk REVIT Architecture – What does it mean and why do I need it?",
      path: `${PageRoutesConstant.Page.Blog.path}/autodesk-revit-architecture`,
      element: <AutodeskRevitArchitecture />,
    },
    BimDesignSolutions: {
      title: "BIM designs solutions | RevitMart",
      path: `${PageRoutesConstant.Page.Blog.path}/bim-design-solutions`,
      element: <BimDesignSolutions />,
    },
    RevitForRetail: {
      title: "Using Revit for retail design",
      path: `${PageRoutesConstant.Page.Blog.path}/revit-for-retail`,
      element: <RevitForRetail />,
    },
    MepDraftingCadRevit: {
      title: "MEP Drafting in CAD or Revit",
      path: `${PageRoutesConstant.Page.Blog.path}/mep-drafting-cad-revit`,
      element: <MepDraftingCadRevit />,
    },
    InsightMepDraftingServices: {
      title: "An Insight into the MEP Drafting Services",
      path: `${PageRoutesConstant.Page.Blog.path}/insight-mep-drafting-services`,
      element: <InsightMepDraftingServices />,
    },
    AutocadDraftingServicesComputerAidedDesign: {
      title: "AutoCAD drafting services: Computer Aided Design",
      path: `${PageRoutesConstant.Page.Blog.path}/autocad-drafting-services-computer-aided-design`,
      element: <AutocadDraftingServicesComputerAidedDesign />,
    },
    CadSoftwareValue: {
      title: "CAD software value",
      path: `${PageRoutesConstant.Page.Blog.path}/cad-software-value`,
      element: <CadSoftwareValue />,
    },
    ComprehensiveAnalyzingPowerMechanicalCadDrafting: {
      title: "Comprehensive analyzing the power of Mechanical Cad Drafting",
      path: `${PageRoutesConstant.Page.Blog.path}/comprehensive-analyzing-power-mechanical-cad-drafting`,
      element: <ComprehensiveAnalyzingPowerMechanicalCadDrafting />,
    },
    ConsideredOutsourcingRevitDrafting: {
      title: "Have you considered outsourcing Revit Drafting?",
      path: `${PageRoutesConstant.Page.Blog.path}/considered-outsourcing-revit-drafting`,
      element: <ConsideredOutsourcingRevitDrafting />,
    },
    AutodeskRevitArchitectureMeanNeed: {
      title:
        "Autodesk REVIT Architecture – What does it mean and why do I need it?",
      path: `${PageRoutesConstant.Page.Blog.path}/autodesk-revit-architecture-mean-need`,
      element: <AutodeskRevitArchitectureMeanNeed />,
    },
    BenefitsBimDesignImplementation: {
      title: "Benefits of BIM in Design and Implementation",
      path: `${PageRoutesConstant.Page.Blog.path}/benefits-bim-design-implementation`,
      element: <BenefitsBimDesignImplementation />,
    },
    ChooseBimDesignSolutions: {
      title: "Why Choose our BIM design solutions?",
      path: `${PageRoutesConstant.Page.Blog.path}/choose-bim-design-solutions`,
      element: <ChooseBimDesignSolutions />,
    },
    OutsourcingDraftingBenefits: {
      title: "Why outsourcing drafting benefits?",
      path: `${PageRoutesConstant.Page.Blog.path}/outsourcing-drafting-benefits`,
      element: <OutsourcingDraftingBenefits />,
    },
    RevitComprehensiveOverviewPartTwo: {
      title: "REVIT – A comprehensive overview. Part 2",
      path: `${PageRoutesConstant.Page.Blog.path}/revit-comprehensive-overview-part-2`,
      element: <RevitComprehensiveOverviewPartTwo />,
    },
    UseRevitDraftingRetailSector: {
      title: "Use of REVIT Drafting for Retail Sector",
      path: `${PageRoutesConstant.Page.Blog.path}/use-revit-drafting-retail-sector`,
      element: <UseRevitDraftingRetailSector />,
    },
    SimulationArchitecturalProjectsImportantAspectsRevitServices: {
      title:
        "Simulation of Architectural Projects- Important aspects of REVIT services",
      path: `${PageRoutesConstant.Page.Blog.path}/simulation-architectural-projects-important-aspects-revit-services`,
      element: <SimulationArchitecturalProjectsImportantAspectsRevitServices />,
    },
    MakeDreamHomeSketchesReality: {
      title: "How do I make my dream home sketches a reality?",
      path: `${PageRoutesConstant.Page.Blog.path}/make-dream-home-sketches-reality`,
      element: <MakeDreamHomeSketchesReality />,
    },
    RevitDraftingCuttingEdgeBuildingIndustry: {
      title: "REVIT Drafting: The Cutting Edge in Building Industry",
      path: `${PageRoutesConstant.Page.Blog.path}/revit-drafting-cutting-edge-building-industry`,
      element: <RevitDraftingCuttingEdgeBuildingIndustry />,
    },
    UsingRevitRetailDesign: {
      title: "Using Revit for Retail Design",
      path: `${PageRoutesConstant.Page.Blog.path}/using-revit-retail-design`,
      element: <UsingRevitRetailDesign />,
    },
    OutsourcingThreedDraftingServices: {
      title: "Outsourcing of 3D Drafting Services",
      path: `${PageRoutesConstant.Page.Blog.path}/outsourcing-3d-drafting-services`,
      element: <OutsourcingThreedDraftingServices />,
    },
    WantReplicatingBuildingBegin: {
      title: "I want that! – Replicating a building, where to begin?",
      path: `${PageRoutesConstant.Page.Blog.path}/want-replicating-building-begin`,
      element: <WantReplicatingBuildingBegin />,
    },
    RevitComprehensiveOverviewPartThree: {
      title: "REVIT – A comprehensive overview. Part 3",
      path: `${PageRoutesConstant.Page.Blog.path}/revit-comprehensive-overview-part-3`,
      element: <RevitComprehensiveOverviewPartThree />,
    },
    RevitImpactDesignTwo: {
      title: "How Does Revit Impact Design",
      path: `${PageRoutesConstant.Page.Blog.path}/revit-impact-design-2`,
      element: <RevitImpactDesignTwo />,
    },
  },
};

const BlogRoutes = () => {
  return (
    <Routes>
      {Object.keys(BlogRoutesConstant.Blog).map((key) => {
        const blogItem = BlogRoutesConstant.Blog[key];
        return (
          <Route
            key={key}
            path={blogItem.path.replace(PageRoutesConstant.Page.Blog.path, "")}
            element={blogItem.element}
          />
        );
      })}
    </Routes>
  );
};

export default BlogRoutes;
