import { FC } from "react";
import { Row, Col } from "react-bootstrap";

const Company: FC = () => {
  return (
    <Row className="g-0">
      <Col xs={12} className="divider">
        <div className="content">
          <p>
            RevitMart is a place where you can find everything you need related
            to your Revit® needs! Over the past few years, RevitMart has grown
            from a 6 person company to approx 20 person firm – Thanks to our
            clients!
          </p>

          <p>
            From simple families to intelligent systems, inspiring shell to
            beautiful interiors, complex MEP Systems to Structures, we build it
            all !
          </p>

          <p>
            Our team consisting of Architects, Engineers and Autodesk trained
            Drafters combined with their specialized Revit® skills has
            successfully completed many small and large projects in Revit®. Our
            company is the only dedicated firm in Washington DC & New York that
            provides specialized services with Revit®!
          </p>

          <p>
            <strong>We specialize in:</strong>
          </p>

          <p>
            <i className="bi bi-check-lg"></i> High Rise Office Buildings
            <br />
            <i className="bi bi-check-lg"></i> High Rise Condos
            <br />
            <i className="bi bi-check-lg"></i> Large Footprint Commercial
            Buildings
            <br />
            <i className="bi bi-check-lg"></i> Shopping Malls
            <br />
            <i className="bi bi-check-lg"></i> HealthCare Facilities and
            Hospitals
            <br />
            <i className="bi bi-check-lg"></i> Laboratories
            <br />
            <i className="bi bi-check-lg"></i> Complex MEP Central Plants
            <br />
            <i className="bi bi-check-lg"></i> Creating Revit® Families That
            Look 99.9% Real
            <br />
            <i className="bi bi-check-lg"></i> Detailed Energy Modelling using
            BIM
            <br />
            <i className="bi bi-check-lg"></i> Converting PDF Files to Revit 3D
            models
            <br />
            <i className="bi bi-check-lg"></i> Converting 2D AutoCAD® Drawings
            to 3D Revit
            <br />
            <i className="bi bi-check-lg"></i> Making Revit® Families In
            Accordance With The Various Engineering Code Standards
            <br />
            <i className="bi bi-check-lg"></i> Making Company Templates
            <br />
            <i className="bi bi-check-lg"></i> Specialized Rendering Services &
            Walkthrough Movies
            <br />
            <i className="bi bi-check-lg"></i> Modifying Current 3D Content As
            Per Your Needs/Company Standards
            <br />
            <i className="bi bi-check-lg"></i> Modifying Current 3D Content As
            Per Your Needs/Company Standards
          </p>

          <p>
            Being one of the oldest in this industry, we have learnt that having
            content in 3D is much more eye catching than the traditional 2D
            content. As more and more companies are transiting to Revit® and
            many even planning to transit completely to Revit® as their standard
            platform, we believe that this is the best time to invest in
            converting your 2D files into intelligent 3D content.
          </p>

          <p>
            <strong>Some of the benefits of transiting to Revit®:</strong>
          </p>

          <p>
            <i className="bi bi-check-lg"></i> It is the best marketing tool in
            this bad economy or even in the long run
            <br />
            <i className="bi bi-check-lg"></i> It is the latest technology and
            keeps you a head of your competitors
            <br />
            <i className="bi bi-check-lg"></i> Revit® 3D content not only makes
            your content far more presentable, but also holds real time
            information about that part within itself
            <br />
            <i className="bi bi-check-lg"></i> Having your files in Revit®, you
            can either issue your content in Revit® or in traditional AutoCAD®
            format giving you and your clients flexibility to use either as per
            needed
          </p>

          <p>
            With industries being transformed by the latest technology, there is
            ample demand for intelligent 3D content that holds its own
            information. In fact, engineers these days prefer to use
            manufacturers that provide their content in Revit®. Looking at the
            prevailing market, we believe the time is not far when engineers
            start specifying only those manufacturers who provide their content
            in Revit®. As more and more customers and contractors want jobs to
            be delivered in Revit®, Engineers and Architects have started to
            spec manufacturers that provide them with 3D content specifically in
            Revit®.
          </p>

          <p>
            We at RevitMart value your interest and reputation that you have
            built over the past years and would like to offer you our best
            services if given the chance.
          </p>

          <p>
            <strong>Benefits of choosing us:</strong>
          </p>

          <p>
            <i className="bi bi-check-lg"></i> We work with your company staff
            to provide after sales services for them to better understand our
            work
            <br />
            <i className="bi bi-check-lg"></i> We work with your clients to make
            sure they use our content the right way
            <br />
            <i className="bi bi-check-lg"></i> Compressed file sizes for better
            performance
            <br />
            <i className="bi bi-check-lg"></i> Ease of uploading or downloading
            files from our FTP anytime, FREE OF COST
            <br />
            <i className="bi bi-check-lg"></i> We understand the privacy of our
            clients and all our work is confidential
            <br />
            <i className="bi bi-check-lg"></i> FREE Revit customer support for
            our clients
          </p>
          <p>
            P.S.: If you’re Revitting, let RevitMart take care of all your
            troubles! Because we’re looking after you!
          </p>

          <p>
            Please email us at info@revitmart.com with any questions you might
            have. Click here for Revit MEP Tutorials, Revit Architecture
            Tutorials, Revit Structure Tutorials
          </p>

          <p>
            Revit Mart is looking forward to providing Revit Drafting Services
            in the following cities: Montgomery Alabama, Juneau Alaska, Phoenix,
            Arizona, Arkansas, Los Angeles, San Francisco, San Diego,
            California, Denver Colorado, Hartford Connecticut, Dover Delaware,
            Miami Florida, Atlanta Georgia, Big Island, Honolulu Hawaii, Boise
            Idaho, Chicago, Springfield, Joliet, Naperville, Peoria Illinois,
            Indianapolis Indiana, Des Moines Iowa, Topeka Kansas, Frankfort
            Kentucky, Baton Rouge Louisiana, Augusta Maine, Annapolis,
            Baltimore, Rockville Maryland, Boston Massachusetts, Lansing
            Michigan, Saint Paul Minnesota, Jackson Mississippi, Jefferson City
            Missouri, Helena Montana, Lincoln Nebraska, Las Vegas, Carson City
            Nevada, Concord New Hampshire, Trenton New Jersey, Santa Fe New
            Mexico, New York, Raleigh, Durham North Carolina, Bismarck North
            Dakota, Columbus, Cleveland, Cincinnati, Toledo Ohio, Oklahoma City,
            Portland Oregon, Philadelphia Pennsylvania, Providence Rhode Island,
            Columbia South Carolina, Pierre South Dakota, Nashville, Memphis
            Tennessee, Austin, Houston Texas, Salt Lake City Utah, Montpelier
            Vermont, Fairfax, Arlington, Richmond, Alexandria, Virginia,
            Washington, Charleston West Virginia, Madison Wisconsin, Cheyenne
            Wyoming, New York City, NY, Los Angeles, CA, Chicago, IL , Houston,
            TX, Phoenix, AZ, Philadelphia, PA, San Antonio, TX, San Diego, CA,
            Dallas, TX, San Jose, CA, Washington DC, Portland OR, New York, Los
            Angeles, Chicago, Houston, Philadelphia, Phoenix, San Antonio, San
            Diego, Dallas, San Jose, Austin, Jacksonville, San Francisco,
            Indianapolis, Columbus, Fort Worth, Charlotte, Seattle, Denver, El
            Paso, Detroit, Washington, Boston, Memphis, Nashville, Portland,
            Oklahoma City, Las Vegas, Baltimore, Louisville, Milwaukee,
            Albuquerque, Tucson, Fresno, Sacramento, Kansas City, Long Beach
          </p>

          <p>
            <strong>
              Sincerely,
              <br />
              RevitMart Team
            </strong>
          </p>
        </div>
      </Col>
    </Row>
  );
};
export default Company;
