import { FC } from "react";
import { DraftingService1, DraftingService2 } from "../../assets/images";
import { Row, Col } from "react-bootstrap";

const DraftingServices: FC = () => {
  return (
    <div className="drafting-services">
      <Row className="g-0">
        <Col xs={12} className="divider">
          <div className="content">
            <p>
              <strong>Revit Drafting Services – Drafting and Designing </strong>
            </p>
            <p>
              All our clients love us for our low cost drafting services which
              helps save time and money!
              <strong> Revit Drafting Services </strong>
              offers the best standard in BIM drafting and production of BIM
              drawings for construction related purposes in America! Most of our
              work in Drafting comes from BIM drafting and we are proud to have
              exceeded our clients expectations on every single project! Ours
              clients call us the “Revit Sweatshop” and we take that seriously!
              We have full time drafters that are experts in Revit and can draft
              at much faster speeds than normal Revit users. Many of our team
              members are industry experts with years of experience in the
              construction industry and are well known in their fields. <br />
              Companies across the country have trusted RevitMart for Revit BIM
              Drafting, CAD Drafting and Management Services for years.
              RevitMart brings years of construction experience from many
              different trades and perspectives to our clients and understand
              the needs of our clients well whether it be Architects, Engineers,
              Contractors or Manufacturers. Our Prices are very competitive and
              all work is done here in the United States! Get in touch with one
              of our representatives today to see how our Company can help your
              firm, chances are high that your competitors are already saving
              money by using our help on their projects.
            </p>
            <p>
              <strong>We provide the following Revit Drafting Services</strong>
            </p>
            <p>
              Job site surveys and deliver a Revit Model
              <br />
              PDF to CAD conversion
              <br />
              PDF to Revit conversion
              <br />
              CAD to Revit conversion
              <br />
              BIM Design and Analysis
              <br />
              BIM Shop Drawings
              <br />
              Quantity and material takeoffs
              <br />
              On site meetings with clients
            </p>
            <p>
              We adhere to the industrial guidelines as well as the codes
              specific to the construction industry in different states. Because
              we have helped many manufacturers build their content, we have an
              insiders access to most manufacturer’s content.
            </p>
            <Row className="g-0">
              <Col xs={12}>
                <img
                  className="img-fluid"
                  src={DraftingService1}
                  alt="drafting-service"
                  title="Engineering 3D Revit MEP Drafting Services Revit Drafting"
                />
              </Col>
              <Col xs={12}>
                <img
                  className="img-fluid"
                  src={DraftingService2}
                  alt="drafting-service"
                  title="Engineering 3D Revit MEP Drafting Services Revit Drafting"
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default DraftingServices;
