import { FC } from "react";
import { ContactInfoList } from "./ContactInfo.constant";
import { Col, Row } from "react-bootstrap";
import "./ContactInfo.style.scss";

const ContactInfo: FC = () => {
  return (
    <>
      {ContactInfoList.map((item, index) => (
        <Row className="g-0 contact-info" key={index}>
          <Col xs={12} md={6}>
            <Row>
              <Col xs={12}>
                <h3 className="contact-info-heading">Contact Info</h3>
              </Col>
              {item.description && (
                <Col xs={12}>
                  <p>{item.description}</p>
                </Col>
              )}
              <Col xs={12}>
                <Row>
                  <Col xs={12}>
                    <strong>Address:</strong>
                    <br />
                    {item.address.addressLine1}
                    <br />
                    {item.address.addressLine2}
                  </Col>
                  {item.tollFree ? (
                    <Col xs={12}>
                      <strong>Toll Free: </strong>
                      {item.tollFree}
                    </Col>
                  ) : (
                    <Col xs={12}>
                      <strong>Office: </strong>
                      {item.office}
                    </Col>
                  )}
                  <Col xs={12}>
                    <strong>E-Mail: </strong>
                    {item.email}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6} className="my-3 my-md-0">
            <iframe
              title="Map"
              src={item.iframeSource}
              className="contact-info-map"
              width="100%"
            ></iframe>
          </Col>
        </Row>
      ))}
    </>
  );
};
export default ContactInfo;
