import { FC } from "react";
import EnquiryForm from "../../components/enquiry-form/EnquiryForm";
import ContactInfo from "../../components/contact-info/ContactInfo";
import { Row, Col } from "react-bootstrap";

const ContactUs: FC = () => {
  return (
    <div className="contact-us">
      <Row className="g-0">
        <Col xs={12}>
          <div className="content">
            <ContactInfo />
          </div>
        </Col>
        <Col xs={12}>
          <EnquiryForm />
        </Col>
      </Row>
    </div>
  );
};
export default ContactUs;
