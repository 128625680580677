import { FC, useEffect, useState } from "react";
import { XMLParser } from "fast-xml-parser";
import { Col, ListGroup, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./SitemapPage.style.scss";

const SitemapPage: FC = () => {
  const [urls, setUrls] = useState<string[]>([]);
  const sitemapUrl = "sitemap.xml";

  const fetchSitemap = async (url: string): Promise<any> => {
    const response = await fetch(url);
    const xml = await response.text();
    const parser = new XMLParser();
    const result = parser.parse(xml);
    return result;
  };

  useEffect(() => {
    const loadSitemap = async () => {
      try {
        const data = await fetchSitemap(sitemapUrl);
        const urls = data.urlset.url.map((entry: any) => entry.loc);
        setUrls(urls);
      } catch (error) {
        console.error("Failed to fetch sitemap", error);
      }
    };

    loadSitemap();
  }, [sitemapUrl]);

  return (
    <div className="sitemap my-5 mx-5">
      <Row>
        <Col xs={12}>
          <h1 className="heading">Sitemap</h1>
        </Col>
        <Col xs={12}>
          <ListGroup>
            <ListGroup.Item
              disabled
              className="border-top-0 border-start-0 border-end-0"
            >
              <strong>URL</strong>
            </ListGroup.Item>
            {urls.map((url, index) => (
              <ListGroup.Item key={index} as={Link} action to={url}>
                {url}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
      </Row>
    </div>
  );
};

export default SitemapPage;
