import {
  Slide1,
  Slide2,
  Slide3,
  Slide4,
  Slide5,
  Slide6,
  Slide7,
  Slide8,
} from "../../assets/images";

export const CarouselSliders = {
  slides: [
    { image: Slide1 },
    { image: Slide2 },
    { image: Slide3 },
    { image: Slide4 },
    { image: Slide5 },
    { image: Slide6 },
    { image: Slide7 },
    { image: Slide8 },
  ],
  contents: [
    "Award winning Revit services in New York, Washington DC,",
    "San Francisco, Los Angeles, Hawaii, Miami, Portland, Louisiana.",
    "Call us at 202-780-7004 to discuss your needs and goals!",
    "We offer prompt services at very competitive prices since 2008!",
    "We are a certified small business and offer special pricing for GSA projects!",
  ],
};
