import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import PageFlowRoutes from "./components/PageFlowRoutes";
import SitemapPage from "../../pages/sitemap/SitemapPage";
import BlogFlowRoutes from "./components/BlogFlowRoutes";

const MainRoutes: FC = () => {
  return (
    <Routes>
      <Route path="/*" element={<PageFlowRoutes />} />
      <Route path="/blogs/*" element={<BlogFlowRoutes />} />
      <Route path="/sitemap" element={<SitemapPage />} />
    </Routes>
  );
};
export default MainRoutes;
