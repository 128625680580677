import { FC, useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { Variant } from "react-bootstrap/esm/types";

type AutoHideAlertProps = {
  message: string;
  /**
   * @description Seconds
   */
  duration: number;
  variant: Variant;
  setMessage: (value: string) => void;
};

const AutoHideAlert: FC<AutoHideAlertProps> = ({
  message,
  duration,
  variant,
  setMessage,
}) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false);
      setMessage("");
    }, duration);

    // Clean up the timer on component unmount
    return () => clearTimeout(timer);
  }, [duration, setMessage]);

  if (!show) {
    return null;
  }

  return (
    <Alert variant={variant} onClose={() => setShow(false)} dismissible>
      {message}
    </Alert>
  );
};

export default AutoHideAlert;
