import { PageRoutesConstant } from "../router/components/PageRoutes";

export const NavbarConstant = [
  { title: "Revit Mart", path: PageRoutesConstant.Page.Home.path },
  { title: "Revit As Builts", path: PageRoutesConstant.Page.RevitAsBuilts.path },
  {
    title: "Revit Drafting Service",
    child: [
      {
        title: "Drafting Service",
        path: PageRoutesConstant.Page.DraftingServices.path,
      },
      {
        title: "3D Drafting Service",
        path: PageRoutesConstant.Page.ThreeDDraftingServices.path,
      },
      {
        title: "3D Drafting Company",
        path: PageRoutesConstant.Page.ThreeDDraftingCompany.path,
      },
      {
        title: "MEP Drafting Service",
        path: PageRoutesConstant.Page.MepDraftingServices.path,
      },
      {
        title: "CAD Drafting Service",
        path: PageRoutesConstant.Page.CadDraftingServices.path,
      },
    ],
  },
  { title: "Clash Detection", path: PageRoutesConstant.Page.ClashDetection.path },
  { title: "Revit MEP", path: PageRoutesConstant.Page.RevitMep.path },
  {
    title: "Revit Architecture",
    path: PageRoutesConstant.Page.RevitArchitecture.path,
  },
  { title: "Revit Structure", path: PageRoutesConstant.Page.RevitStructure.path },
  { title: "Revit Kitchen", path: PageRoutesConstant.Page.RevitKitchen.path },
  { title: "Shop Drawings", path: PageRoutesConstant.Page.ShopDrawings.path },
  { title: "Laser Scan", path: PageRoutesConstant.Page.LaserScan.path },
  { title: "Company", path: PageRoutesConstant.Page.Company.path },
  { title: "VBM", path: PageRoutesConstant.Page.VBM.path },
];