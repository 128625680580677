import { FC } from "react";
import { DraftingService1, DraftingService2 } from "../../../assets/images";
import { Col, Row } from "react-bootstrap";
import "./ThreeDDraftingCompany.style.scss";

const ThreeDDraftingCompany: FC = () => {
  return (
    <div className="three-d-drafting">
      <Row className="g-0">
        <Col xs={12} className="divider">
          <div className="content">
            <p>
              Find out why we are called the Revit Sweatshop of East Coast! Over
              8 Million Sq. Ft. modeled in Revit just last year! All our clients
              love us for our low-cost 3D drafting services which helps save
              time and money! RevitMart offers the best standard in BIM drafting
              and production of BIM drawings for construction-related purposes
              in America! Most of our work in
              <strong> 3D Drafting Company </strong>comes from BIM drafting and
              we are proud to have exceeded our clients expectations on every
              single project! Ours clients call us the “Revit Sweatshop” and we
              take that seriously! We have full time drafters that are experts
              in Revit and can draft at much faster speeds than normal Revit
              users. Many of our team members of Company are industry experts
              with years of experience in the construction industry and are well
              known in their fields.
            </p>
            <p>
              Companies across the country have trusted RevitMart for Revit BIM
              Drafting, CAD Drafting and Management Services for years.
              RevitMart as 3D Drafting Company brings years of construction
              experience from many different trades and perspectives to our
              clients and understand the needs of our clients well whether it be
              Architects, Engineers, Contractors or Manufacturers. Our Prices
              are very competitive and all work is done here in the United
              States! Get in touch with one of our representatives today to see
              how 3D Drafting Company helps your firm, chances are high that
              your competitors are already saving money by using our help on
              their projects.
            </p>
            <h2 className="heading">
              We provide following 3D Drafting services:
            </h2>
            <p>
              Job site surveys and deliver a Revit Model
              <br />
              PDF to CAD conversion
              <br />
              PDF to Revit conversion
              <br />
              CAD to Revit conversion
              <br />
              BIM Design and Analysis
              <br />
              BIM Shop Drawings
              <br />
              Quantity and material takeoffs
              <br />
              On site meetings with clients
            </p>
            <p>
              We adhere to the industrial guidelines as well as the codes
              specific to the construction industry in different states. Because
              our Company have helped many manufacturers build their content, we
              have an insiders access to most manufacturer’s content.
            </p>
            <Row className="g-0">
              <Col xs={12}>
                <img
                  className="img-fluid"
                  src={DraftingService1}
                  alt="drafting-service"
                  title="Engineering 3D Revit MEP Drafting Services Revit Drafting"
                />
              </Col>
              <Col xs={12}>
                <img
                  className="img-fluid"
                  src={DraftingService2}
                  alt="drafting-service"
                  title="Engineering 3D Revit MEP Drafting Services Revit Drafting"
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default ThreeDDraftingCompany;
