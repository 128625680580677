import { FC, useState } from "react";
import { VBM1, VBM2, VBM3, VBM4, VBM5 } from "../../assets/images";
import { Button, Col, Row, Toast, ToastContainer } from "react-bootstrap";
import { loadStripeScript } from "../../services/StripeService";
import { useNavigate } from "react-router-dom";
import { PageRoutesConstant } from "../../components/router/components/PageRoutes";
import "./VBM.style.scss";

const VBM: FC = () => {
  const navigate = useNavigate();
  const [showMessage, setShowMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const paymentDetails = {
    name: "RevitMart",
    description: "Virtual BIM Manager",
    amount: 9900, // in cents
    currency: "USD",
  };

  const handlePayment = () => {
    loadStripeScript(
      paymentDetails.name,
      paymentDetails.description,
      paymentDetails.amount,
      paymentDetails.currency,
      PageRoutesConstant.Page.Home.path,
      navigate,
      (isSuccess) => {
        setShowMessage(isSuccess);
      },
      (isError, message) => {
        setShowErrorMessage(isError);
        setErrorMessage(message);
      }
    );
  };

  return (
    <div className="vbm">
      <ToastContainer position="top-end" className="p-3 toast-container-custom">
        <Toast
          show={showMessage}
          onClose={() => setShowMessage(false)}
          bg="success"
          delay={5000}
          autohide
        >
          <Toast.Body className="text-white">
            Thank You! Your payment was successful.
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <ToastContainer position="top-end" className="p-3 toast-container-custom">
        <Toast
          show={showErrorMessage}
          onClose={() => setShowErrorMessage(false)}
          bg="danger"
          delay={5000}
          autohide
        >
          <Toast.Body className="text-white">
            {errorMessage}
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <Row className="g-0">
        <Col xs={12} className="divider">
          <div className="content">
            <Row className="g-0">
              <Col xs={12} className="mb-3">
                <Button
                  className="stripe-pay-now-button"
                  onClick={handlePayment}
                >
                  Pay Now
                </Button>
              </Col>
            </Row>
            <p>
              RevitMart’s VBM (Virtual BIM Manager) is a powerful new tool that
              allows you to quickly resolve issues with your models. The tool
              appears in its own tab in Revit’s ribbon. Revit models can become
              very valuable, and unfortunately, very messy. VBM can make an
              hours-long cleanup take only a few minutes, and help you continue
              to keep your model looking and running great.
            </p>

            <p>The Model Cleanup tool features:</p>

            <ul>
              <li className="my-1">
                <strong>‘One Touch Model Cleanup’ in under 5 minutes</strong>
              </li>
              <li className="my-1">
                <strong>
                  Automatically remove imported CAD junk from the model
                </strong>
              </li>
              <li className="my-1">
                <strong>
                  Automatically remove unwanted linked models and CAD files
                </strong>
              </li>
              <li className="my-1">
                <strong>
                  Automatically remove unwanted Legends & Schedules
                </strong>
              </li>
              <li className="my-1">
                <strong>
                  Automatically delete all Detail Groups & Model Groups
                </strong>
              </li>
              <li className="my-1">
                <strong>Automatically delete all Sheets</strong>
              </li>
              <li className="my-1">
                <strong>Automatically delete all Views</strong>
              </li>
              <li className="my-1">
                <strong>Powerful tool to resolve unknown errors</strong>
              </li>
              <li className="my-1">
                <strong>Includes Free Setup & Upgrade</strong>
              </li>
              <li className="my-1">
                <strong>Includes Free Training & Support</strong>
              </li>
            </ul>

            <p>
              The model cleanup tool allows you to edit and delete multiple
              views, sheets, legends, schedules, model groups, links, and CAD
              imports. The tool also allows you to view these elements in one
              place, which makes it quicker to use than the purge tool. Elements
              can be renamed here as well. The Master Cleanup tool is like a
              reset button for your project. It allows you to delete all of the
              aforementioned elements while keeping your model intact.
            </p>

            <p>The Casing Manager allows you to:</p>

            <ul>
              <li className="my-1">
                <strong>
                  View text notes, room names, sheet names & numbers, view
                  names, and dimensions in a single list
                </strong>
              </li>
              <li className="my-1">
                <strong>
                  Convert items to all lowercase, uppercase, sentence case, or
                  title case
                </strong>
              </li>
              <li className="my-1">
                <strong>
                  Ignore a single letter “x” (Used when denoting dimensions)
                </strong>
              </li>
            </ul>

            <p>
              The casing manager allows you to selectively change the case of
              any editable-text element and to continue that change throughout
              your project. Case can be changed to all upper, all lower, title
              case, where every first letter is capitalized, and sentence case,
              where only the first letter is capitalized. You can also ignore
              the letter “x” when it is used for denoting size. It also allows
              you to view all the items in one place, which makes error checking
              much easier.
            </p>

            <Row className="g-0">
              <Col xs={12} className="mb-3">
                <Button
                  className="stripe-pay-now-button"
                  onClick={handlePayment}
                >
                  Pay Now
                </Button>
              </Col>
            </Row>

            <h5 className="screen-shot-title">Screenshots:</h5>
            <Row>
              <Col xs={12}>
                <img
                  className="img-fluid"
                  src={VBM1}
                  alt="RevitMart Virtual BIM Manager"
                />
                <div className="fst-italic">RevitMart Virtual BIM Manager</div>
              </Col>
              <Col xs={12}>
                <img
                  className="img-fluid"
                  src={VBM2}
                  alt="RevitMart Virtual BIM Manager"
                />
                <div className="fst-italic">RevitMart Virtual BIM Manager</div>
              </Col>
              <Col xs={12}>
                <img
                  className="img-fluid"
                  src={VBM3}
                  alt="RevitMart Virtual BIM Manager"
                />
                <div className="fst-italic">RevitMart Virtual BIM Manager</div>
              </Col>
              <Col xs={12}>
                <img
                  className="img-fluid"
                  src={VBM4}
                  alt="RevitMart Virtual BIM Manager"
                />
                <div className="fst-italic">RevitMart Virtual BIM Manager</div>
              </Col>
              <Col xs={12}>
                <img
                  className="img-fluid"
                  src={VBM5}
                  alt="RevitMart Virtual BIM Manager"
                />
                <div className="fst-italic">RevitMart Virtual BIM Manager</div>
              </Col>
            </Row>

            <Row className="g-0">
              <Col xs={12} className="mt-3">
                <Button
                  className="stripe-pay-now-button"
                  onClick={handlePayment}
                >
                  Pay Now
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default VBM;
