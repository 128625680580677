import { FC } from "react";
import { Col, ListGroup, Row } from "react-bootstrap";
import { BlogRoutesConstant } from "../../components/router/components/BlogRoutes";
import { Link } from "react-router-dom";
import "./Blog.style.scss";

const Blog: FC = () => {
  return (
    <div className="blog">
      <Row className="g-0">
        <Col xs={12} className="blog-list">
          <ListGroup>
            <Row className="g-3">
              {Object.keys(BlogRoutesConstant.Blog).map((key, index) => {
                return BlogRoutesConstant.Blog[key].title ? (
                  <Col xs={12} key={`blog_${index}`}>
                    <ListGroup.Item
                      className="border-0"
                      as={Link}
                      action
                      to={BlogRoutesConstant.Blog[key].path}
                    >
                      <h1 className="title mb-0">
                        {BlogRoutesConstant.Blog[key].title}
                      </h1>
                    </ListGroup.Item>
                  </Col>
                ) : null;
              })}
            </Row>
          </ListGroup>
        </Col>
      </Row>
    </div>
  );
};
export default Blog;
