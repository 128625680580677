import { FC } from "react";
import { useNavigate } from "react-router-dom";
import {
  Architecture,
  LaserScan1,
  MEP,
  RevitMartDrafting,
} from "../../assets/images";
import { Col, Row } from "react-bootstrap";
import "./Home.style.scss";

const Home: FC = () => {
  const navigate = useNavigate();
  return (
    <div className="home">
      <Row className="g-0 content">
        <Col xs={12}>
          <ul className="list p-0">
            <li>
              <strong>
                <i className="bi bi-check-lg"></i>&nbsp;
              </strong>
              <strong>3D REVIT Drafting</strong>
            </li>
            <li>
              <strong>
                <i className="bi bi-check-lg"></i>&nbsp;
              </strong>
              <strong>PDF to CAD Conversion</strong>
            </li>
            <li>
              <strong>
                <i className="bi bi-check-lg"></i>&nbsp;
              </strong>
              <strong>PDF to Revit CONVERSION</strong>
            </li>
            <li>
              <strong>
                <i className="bi bi-check-lg"></i>&nbsp;
              </strong>
              <strong>2d CAD to 3d Revit conversion</strong>
            </li>
            <li>
              <strong>
                <i className="bi bi-check-lg"></i>&nbsp;
              </strong>
              <strong>2D & 3D shop drawings</strong>
            </li>
            <li>
              <strong>
                <i className="bi bi-check-lg"></i>&nbsp;
              </strong>
              <strong>2D & 3D REVIT as-builts</strong>
            </li>
            <li>
              <strong>
                <i className="bi bi-check-lg"></i>&nbsp;
              </strong>
              <strong>3D LASER SCAN</strong>
            </li>
          </ul>
        </Col>
        <Col xs={12} className="description">
          <strong>
            Award winning Revit drafting services in New York, Washington DC,
          </strong>
          <br />
          <strong>
            San Francisco, Los Angeles, Hawaii, Miami, Portland, Louisiana.
          </strong>
          <br />
          <strong>
            We offer prompt services at very competitive prices since 2008!
          </strong>
          <br />
          <strong>
            We are a certified small business and offer special pricing for GSA
            projects!
          </strong>
        </Col>

        <Col xs={12} className="notes">
          <strong>
            NOTE: YOU CAN NOW REQUEST THE LATEST VERSION OF
            <span onClick={() => navigate("/virtual-bim-manager-vbm")}>
              REVITMART VBM
            </span>
          </strong>
          <br />
          <strong>
            <span> SOFTWARE</span> BY CONTACTING YOUR LOCAL REPRESENTATIVE OR BY
            EMAILING
          </strong>
          <br />
          <strong>US AT INFO@REVITMART.</strong>
        </Col>

        <Col xs={12}>
          <Row className="g-0">
            <Col xs={12}>
              <p className="text-justify">
                Companies across the country have trusted RevitMart for Revit
                BIM Drafting, CAD Drafting and Management Services for years.
                RevitMart brings years of construction experience from many
                different trades and perspectives to our clients and understand
                the needs of our clients well whether it be Architects,
                Engineers, Contractors or Manufacturers. Our Prices are very
                competitive and all work is done here in the United States.
              </p>
            </Col>
            <Col xs={12}>
              <p className="text-justify">
                From simple Revit families to intelligent Revit BIM systems,
                from inspiring shell to beautiful interiors, from complex MEP
                systems to gigantic Structures, High Rise Office Buildings and
                Condos, Central Plants, Large Footprint Buildings, Existing
                Buildings, Tenant Fitouts, Latest Laser Scanning Technology,
                Revit Drafting, you name it and we build it!
              </p>
            </Col>
          </Row>
          <Row className="g-0">
            <Col xs={{ span: 12, order: 2 }} lg={{ span: 5, order: 1 }}>
              <p className="text-justify">
                Our team consisting of experienced Architects and Engineers
                combined with their specialized Revit® skills have successfully
                completed many small and large projects in Revit®. Our company
                is the only dedicated firm in New York and Wasington DC that
                provides specialized Revit services! We specialize in award
                winning customer service and getting companies head started with
                their Revit BIM services.
              </p>
              <ul className="point-list p-0">
                <li>
                  <i className="bi bi-check-lg"></i> Job site surveys for
                  accurate existing conditions
                </li>
                <li>
                  <i className="bi bi-check-lg"></i> PDF to CAD, PDF to Revit,
                  CAD to Revit conversions
                </li>
                <li>
                  <i className="bi bi-check-lg"></i> Converting 2D AutoCAD
                  Drawings to 3D Revit BIM models
                </li>
                <li>
                  <i className="bi bi-check-lg"></i> JAccurate coordination and
                  clash detection between different trades of projects
                </li>
                <li>
                  <i className="bi bi-check-lg"></i> Free Revit support for our
                  clients
                </li>
                <li>
                  <i className="bi bi-check-lg"></i> US Based Full Time Revit
                  Services Company
                </li>
                <li>
                  <i className="bi bi-check-lg"></i> Over 100 million sq. ft. in
                  projects completed
                </li>
                <li>
                  <i className="bi bi-check-lg"></i> Long list of satisfied
                  clients
                </li>
              </ul>
            </Col>
            <Col xs={{ span: 12, order: 1 }} lg={{ span: 7, order: 2 }}>
              <img
                src={RevitMartDrafting}
                alt="Revit Drafting"
                className="img-fluid"
              />
              <div className="fst-italic">RevitMart Drafting</div>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="inner_wrapper">
        <Row className="gap-4 justify-content-md-center">
          <Col xs={12} md={3} className="wpb_wrapper">
            <div
              className="image-content"
              data-aos="zoom-in"
              data-aos-once="false"
            >
              <h2 className="heading">MEP BIM</h2>
              <img className="img-fluid" src={MEP} alt="mep" />
            </div>
            <div className="text-content">
              <p className="mt-3">
                We provide professional MEP CAD and Revit drafting services to
                Architects, Engineers, Manufacturers and contractors. We take
                pride in our work and clients feel the pride. Contact us today
                for more details.
              </p>
            </div>
          </Col>
          <Col xs={12} md={3} className="wpb_wrapper">
            <div
              className="image-content"
              data-aos="zoom-in"
              data-aos-once="false"
            >
              <h2 className="heading">Architecture BIM</h2>
              <img className="img-fluid" src={Architecture} alt="mep" />
            </div>
            <div className="text-content">
              <p className="mt-3">
                We provide professional Architectural CAD and Revit drafting
                services to Architects, Engineers, Manufacturers and
                contractors. Check out why Architects trust us with our work. We
                have 90% repeat clients. We take our work and reputation
                seriously.
              </p>
            </div>
          </Col>
          <Col xs={12} md={3} className="wpb_wrapper">
            <div
              className="image-content"
              data-aos="zoom-in"
              data-aos-once="false"
            >
              <h2 className="heading">Laser Scan BIM</h2>
              <img className="img-fluid" src={LaserScan1} alt="mep" />
            </div>
            <div className="text-content">
              <p className="mt-3">
                Creating as-builts has never been easier. You call us, we scan
                the building with our hight tech scanners and issue you an
                accurate Revit Model.
              </p>
              <p>
                RevitMart delivers coordinated as built model, providing
                enormous time savings.
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default Home;
