import { FC } from "react";
import { Breadcrumb } from "react-bootstrap";
import { PageRoutesConstant } from "../router/components/PageRoutes";
import { Link } from "react-router-dom";
import "./Breadcrumbs.style.scss";

type BlogPath = {
  name: string;
  path: string;
};

type BreadcrumbsProps = {
  blog?: BlogPath;
  currentPageName: string | undefined;
};

const Breadcrumbs: FC<BreadcrumbsProps> = ({ blog, currentPageName }) => {
  return (
    <Breadcrumb className="bread-crumb">
      <Breadcrumb.Item
        linkAs={Link}
        linkProps={{ to: PageRoutesConstant.Page.Home.path }}
      >
        RevitMart | Revit Drafting
      </Breadcrumb.Item>

      {blog && (
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: blog.path }}>
          {blog.name}
        </Breadcrumb.Item>
      )}

      <Breadcrumb.Item active>{currentPageName}</Breadcrumb.Item>
    </Breadcrumb>
  );
};
export default Breadcrumbs;
