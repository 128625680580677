import { Navigate, Route, Routes } from "react-router-dom";
import Home from "../../../pages/home/Home";
import DraftingServices from "../../../pages/drafting-services/DraftingServices";
import ThreeDDraftingServices from "../../../pages/drafting-services/threed-drafting-services/ThreeDDraftingServices";
import RevitAsBuilts from "../../../pages/revit-as-builts/RevitAsBuilts";
import ThreeDDraftingCompany from "../../../pages/drafting-services/threed-drafting-company/ThreeDDraftingCompany";
import MepDraftingServices from "../../../pages/drafting-services/mep-drafting-services/MepDraftingServices";
import CadDraftingServices from "../../../pages/drafting-services/cad-drafting-services/CadDraftingServices";
import ClashDetection from "../../../pages/clash-detection/ClashDetection";
import RevitMep from "../../../pages/revit-mep/RevitMep";
import RevitArchitecture from "../../../pages/revit-architecture/RevitArchitecture";
import RevitStructure from "../../../pages/revit-structure/RevitStructure";
import RevitKitchen from "../../../pages/revit-kitchen/RevitKitchen";
import ShopDrawings from "../../../pages/shop-drawings/ShopDrawings";
import LaserScan from "../../../pages/laser-scan/LaserScan";
import Company from "../../../pages/company/Company";
import VBM from "../../../pages/vbm/VBM";
import ContactUs from "../../../pages/contact-us/ContactUs";
import Representative from "../../../pages/representative/Representative";
import Careers from "../../../pages/careers/Careers";
import BecomeAssociate from "../../../pages/become-associate/BecomeAssociate";
import { IRouteKey } from "../../../interfaces/RouteKey";

interface IRoute {
  Page: IRouteKey;
}

export const PageRoutesConstant: IRoute = {
  Page: {
    Home: {
      title: "RevitMart | Your partner in design",
      path: "/",
      element: <Home />,
    },
    RevitAsBuilts: {
      title: "Revit As Builts",
      path: "/revit-as-builts",
      element: <RevitAsBuilts />,
    },
    DraftingServices: {
      title: "Revit Drafting Services",
      path: "/revit-drafting-services",
      element: <DraftingServices />,
    },
    ThreeDDraftingServices: {
      title: "3D Drafting Services",
      path: "/3d-drafting-services",
      element: <ThreeDDraftingServices />,
    },
    ThreeDDraftingCompany: {
      title: "3D Drafting Company",
      path: "/3d-drafting-company",
      element: <ThreeDDraftingCompany />,
    },
    MepDraftingServices: {
      title: "MEP Drafting Services",
      path: "/mep-drafting-services",
      element: <MepDraftingServices />,
    },
    CadDraftingServices: {
      title: "CAD Drafting Services",
      path: "/cad-drafting-services",
      element: <CadDraftingServices />,
    },
    ClashDetection: {
      title: "Clash Detection",
      path: "/revit-clash-detection",
      element: <ClashDetection />,
    },
    RevitMep: {
      title: "Revit MEP",
      path: "/revit-mep-drafting",
      element: <RevitMep />,
    },
    RevitArchitecture: {
      title: "Revit Architecture",
      path: "/revit-architecture",
      element: <RevitArchitecture />,
    },
    RevitStructure: {
      title: "Revit Structure",
      path: "/revit-structure",
      element: <RevitStructure />,
    },
    RevitKitchen: {
      title: "Revit Kitchen",
      path: "/revit-kitchen",
      element: <RevitKitchen />,
    },
    ShopDrawings: {
      title: "Shop Drawings",
      path: "/shop-drawings",
      element: <ShopDrawings />,
    },
    LaserScan: {
      title: "Laser Scan",
      path: "/revit-laser-scan",
      element: <LaserScan />,
    },
    Company: {
      title: "Company",
      path: "/company",
      element: <Company />,
    },
    VBM: {
      title: "Virtual BIM Manager",
      path: "/virtual-bim-manager-vbm",
      element: <VBM />,
    },
    ContactUs: {
      title: "Contact us",
      path: "/contact-us",
      element: <ContactUs />,
    },
    Representative: {
      title: "Representative",
      path: "/representative",
      element: <Representative />,
    },
    Careers: {
      title: "Join our team!",
      path: "/careers",
      element: <Careers />,
    },
    BecomeAssociate: {
      title: "Become RevitMart Associate",
      path: "/become-revitmart-associate",
      element: <BecomeAssociate />,
    },
    Blog: {
      path: "/blogs",
    },
    NotFound: {
      path: "*",
      element: <Navigate to="/" />,
    },
  },
};

const PageRoutes = () => {
  return (
    <Routes>
      {Object.keys(PageRoutesConstant.Page).map((key) => {
        const pageItem = PageRoutesConstant.Page[key];
        return (
          <Route key={key} path={pageItem.path} element={pageItem.element} />
        );
      })}
    </Routes>
  );
};

export default PageRoutes;
