import { FC } from "react";
import { Col, Row } from "react-bootstrap";
import { AdminLogo } from "../../../assets/images";
import { Link } from "react-router-dom";

const BenefitsBimDesignImplementation: FC = () => {
  return (
    <Row className="g-5">
      <Col xs={12}>
        <div className="title">
          <h1 className="heading">
            Benefits of BIM in Design and Implementation
          </h1>
        </div>

        <p className="content-heading">
          <strong>Benefits of BIM in Design and Implementation</strong>
        </p>

        <p>
          Recently there was news about Johan Von Konow, a Dutch engineer who
          created an awesome gingerbread house – not by using the traditional
          techniques, but by utilizing a state of the art 50W laser engraver
          along with an ordinary CAD program!
        </p>

        <p>
          What Johan did was that he first conjured up an “accurate, 3D model”
          of the summer house and then proceeded to turn the laser on to the
          large blocks of the gingerbread
        </p>

        <p>
          While this may appear to be a rather simplistic use of the AutoCAD
          technology, it drives home the point of how it can be used to get the
          solutions for larger, more complex projects that require greater
          detail.
        </p>

        <p>
          Using the
          <strong>
            &nbsp; Revit Drafting Services, CAD Drafting Services, MEP Drafting
            Services, and 3D Drafting Services,
          </strong>
          you can generate accurate documentation and design; whether, its for
          the construction of a large building; a small piece of furniture; or
          the state-of-the-art restaurant that you always dreamt of. Who uses
          CAD Software?
        </p>

        <p>
          <strong>Who uses CAD Software?</strong>
        </p>

        <p>
          Well, computer-aided design or CAD is hugely popular in the
          construction and manufacturing industry. This software can generate
          accurate plans and blueprints that can be used to produce goods and
          construct buildings. The technology is very user friendly and it is
          fairly easy to incorporate quick modifications, convert two and three
          dimensional views and designs and generate the supply lists
          automatically.
        </p>

        <p>
          Not only does it create the 2D or 3D (two- or three-dimensional)
          graphical representation of objects, it may also be modified and used
          for specific applications. CAD applications also find a wide use in
          computer animation and creating special effects in media like in the
          movies and advertising, where graphic design is, itself, a finished
          product.
        </p>

        <p>
          One of the biggest uses of CAD is to design products physically in a
          vast range of industries. The software performs calculations to arrive
          at the optimum design for the industrial design of applications and
          products.
        </p>

        <p>
          <strong>AutoCAD Revit, REVIT Drafting, and CAD Drafting,</strong>
          can be used mainly to create detailed 3D surface models or generate 2D
          drawings of physical components. But what is important to note here is
          that &nbsp;
          <strong>
            Revit Drafting Services, CAD Drafting Services, MEP Drafting
            Services and 3D Drafting Services
          </strong>
          can also be used to control the engineering process right from the
          conceptual design phase up to the layout of products. If you are
          looking for an efficient and reliable provider who can match all your
          expectations, &nbsp;
          <Link className="link-tag" to="http://www.revitmart.com">
            www.revitmart.com
          </Link>
          &nbsp; has a highly specialized staff that will ensure that you get
          the best value for your money.
        </p>

        <p>
          <strong>Benefits of BIM</strong>
        </p>

        <p>
          <strong> AutoCAD Revit and CAD Drafting </strong>can be used to carry
          out a dynamic analysis of the assemblies and manufacturing methods. It
          makes it easy for an engineer to automatically and interactively
          analyze the design variability and finalize the optimal design, while
          at the same time minimizing the necessity of physical prototype
          models. It ensures:
        </p>

        <ul>
          <li className="my-2">
            Increased productivity, lower development cost, improved quality and
            faster delivery
          </li>
          <li className="my-2">
            Accurate visualization of the product before commencing production.
          </li>
          <li className="my-2">
            The sub-assemblies, speed up the design process.
          </li>
          <li className="my-2">
            Reduction in errors as the software is designed for a greater
            accuracy.
          </li>
          <li className="my-2">
            An easier, highly robust documentation of the design (geometries and
            dimensions), small and essential items, bills of material, etc.
          </li>
          <li className="my-2">
            An easy to use design data that can be easily modified to suit the
            specific needs.
          </li>
        </ul>

        <p>
          In case you wish to avail any of these services, simply go to &nbsp;
          <Link className="link-tag" to="http://www.revitmart.com">
            www.revitmart.com
          </Link>
          &nbsp; and get in touch with the REVITMART helpdesk, who will guide
          you through the entire process.
        </p>

        <p>
          <i className="fa fa-tag" /> 3D Drafting Company, 3D Drafting Services,
          AutoCAD Revit, CAD Drafting, CAD Drafting Services, Engineering
          Drafting Services, MEP Drafting Services, Revit Company, Revit
          Drafting, Revit Drafting Service, Revit Services
        </p>
      </Col>
      <Col xs={12}>
        <div className="entry-author">
          <img src={AdminLogo} alt="admin" />
          <p className="writer-name">
            Written by <span className="text-dark">admin</span>
          </p>
        </div>
      </Col>
    </Row>
  );
};
export default BenefitsBimDesignImplementation;
