export { default as Logo } from "./logo.png";
export { default as MEP } from "./home/mep.jpg";
export { default as RevitMartDrafting } from "./home/RevitMart-Drafting.jpg";
export { default as Architecture } from "./home/architecture.jpg";
export { default as LaserScan1 } from "./home/laserscan.jpg";
export { default as Slide1 } from "./slider/sl-1.png";
export { default as Slide2 } from "./slider/sl-2.png";
export { default as Slide3 } from "./slider/sl-3.jpg";
export { default as Slide4 } from "./slider/sl-4.jpg";
export { default as Slide5 } from "./slider/sl-5.jpg";
export { default as Slide6 } from "./slider/sl-6.jpg";
export { default as Slide7 } from "./slider/sl-7.jpg";
export { default as Slide8 } from "./slider/sl-8.jpg";
export { default as DraftingService1 } from "./drafting-service/img-1.png";
export { default as DraftingService2 } from "./drafting-service/img-2.png";
export { default as MepDraftingService } from "./drafting-service/map-drafting.png";
export { default as RevitMartSurvay } from "./revit-as-built/RevitMart-Survey.jpg";
export { default as DWG } from "./revit-as-built/DWG.png";
export { default as PDF } from "./revit-as-built/PDF.png";
export { default as Photos } from "./revit-as-built/Photos.png";
export { default as RevitLogo1 } from "./revit-as-built/RevitLogo1.png";
export { default as ClashDetection1 } from "./clash-detection/clash-detection.png";
export { default as RevitMep1 } from "./revit-mep/revit-mep1.jpg";
export { default as RevitMep2 } from "./revit-mep/revit-mep2.jpg";
export { default as Architecture2 } from "./revit-architecture/ravit-architecture1.jpg";
export { default as Structure1 } from "./revit-structure/revit-structure.png";
export { default as Kitchen1 } from "./revit-kitchen/revit-kitchen1.jpg";
export { default as Kitchen2 } from "./revit-kitchen/revit-kitchen2.jpg";
export { default as ShopDrawings1 } from "./shop-drawings/shop-drawings1.jpg";
export { default as ShopDrawings2 } from "./shop-drawings/shop-drawings2.png";
export { default as LaserScan2 } from "./laser-scan/laser-scan2.png";
export { default as LaserScan3 } from "./laser-scan/laser-scan3.jpg";
export { default as LaserScan4 } from "./laser-scan/laser-scan4.jpg";
export { default as LaserScan5 } from "./laser-scan/laser-scan5.jpg";
export { default as LaserScan6 } from "./laser-scan/laser-scan6.jpg";
export { default as LaserScan7 } from "./laser-scan/laser-scan7.png";
export { default as LaserScan8 } from "./laser-scan/laser-scan8.png";
export { default as VBM1 } from "./vbm/vbm-1.png";
export { default as VBM2 } from "./vbm/vbm-2.png";
export { default as VBM3 } from "./vbm/vbm-3.png";
export { default as VBM4 } from "./vbm/vbm-4.png";
export { default as VBM5 } from "./vbm/vbm-5.png";
export { default as AdminLogo } from "./blog/admin.png";
