import { FC } from "react";
import { Architecture2 } from "../../assets/images";
import { Col, Row } from "react-bootstrap";

const RevitArchitecture: FC = () => {
  return (
    <Row className="g-0">
      <Col xs={12} className="divider">
        <div className="content">
          <p>
            We provide professional Revit BIM CAD Architectural drafting
            services to Architects, Engineers, Manufacturers and contractors.
          </p>

          <p>
            RevitMart delivers coordinated construction documents at all times,
            providing enormous time savings and quality improvements in Revit
            Architecture drawing deliverables.
          </p>

          <p>
            Revit is a BIM ( Building Information Modeling ) program. The idea
            is to create a 3D model or “virtual” representation of the real
            physical building. Each 3D element has Information ( referred to as
            “metadata” ) input into it so that the data can be scheduled
            automatically, and shared downstream with other stakeholders and
            team members who may use Revit or other programs such as Navisworks
            to combine all models from the various design teams ( Arch.,
            Struct., Civil, MEP/FP, etc. ) into a “Master Model” and perform 3D
            clash detection, 4D scheduling and 5D cost estimating.
          </p>

          <p>
            <strong>
              Below are some of the benefits we provide when you use RevitMart
              as your BIM Consultant:
            </strong>
          </p>

          <p>
            <i className="bi bi-check-lg"></i> We provide automatic
            sheet/drawing references which ensures that no section, elevation,
            or callout ever references the wrong drawing or sheet
            <br />
            <i className="bi bi-check-lg"></i> Bidirectional associativity to
            ensure all changes to any part of the building need to be made only
            once
            <br />
            <i className="bi bi-check-lg"></i> Conceptual cost estimating
            <br />
            <i className="bi bi-check-lg"></i> Ability to modify geometry by
            typing a new number, saving time during iterative design processes
            <br />
            <i className="bi bi-check-lg"></i> Integrated building materials
            <br />
            <i className="bi bi-check-lg"></i> Integrated project phasing
            <br />
            <i className="bi bi-check-lg"></i> Parametric design
            <br />
            <i className="bi bi-check-lg"></i> Project templates
            <br />
            <i className="bi bi-check-lg"></i> Relationships between parametric
            components
            <br />
            <i className="bi bi-check-lg"></i> Single multiuser building
            database
            <br />
            <i className="bi bi-check-lg"></i> Butt-glazed curtain walls
            <br />
            <i className="bi bi-check-lg"></i> Demolition and construction
            phasing
            <br />
            <i className="bi bi-check-lg"></i> Prints sets/saved print settings
            <br />
            <i className="bi bi-check-lg"></i> Scale-based line weight control
            <br />
            <i className="bi bi-check-lg"></i> Title block families
            <br />
            <i className="bi bi-check-lg"></i> Change history audit trail
            <br />
            <i className="bi bi-check-lg"></i> Graphic controls for linked Revit
            models
            <br />
            <i className="bi bi-check-lg"></i> Multiuser worksets
            <br />
            <i className="bi bi-check-lg"></i> On-demand database reading
            <br />
            <i className="bi bi-check-lg"></i> Runtime shared parameters
            <br />
            <i className="bi bi-check-lg"></i> Rendering
            <br />
            <i className="bi bi-check-lg"></i> Animated walkthroughs
            <br />
            <i className="bi bi-check-lg"></i> Material take-off for detailed
            cost estimating
            <br />
            <i className="bi bi-check-lg"></i> Interface with Excel spreadsheets
          </p>

          <p>
            PS: ‘RevitMart’ is a place where you can find everything you need
            related to your Revit® needs!
          </p>

          <Row>
            <Col xs={12}>
              <img
                className="img-fluid"
                src={Architecture2}
                alt="Revit Architecture Drafting"
                title="Revit Architecture Drafting"
              />
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};
export default RevitArchitecture;
