import { FC } from "react";
import { Link } from "react-router-dom";
import { PageRoutesConstant } from "../../components/router/components/PageRoutes";
import RequestFrom from "../../components/request-form/RequestFrom";
import { Row, Col } from "react-bootstrap";
import "./BecomeAssociate.style.scss";

const BecomeAssociate: FC = () => {
  return (
    <div className="become-associate">
      <Row className="g-0">
        <Col xs={12} className="divider">
          <div className="content">
            <p className="text-justify">
              Thank you for your interest in becoming a RevitMart® Associate! We
              understand the importance of our Associates in bringing this
              company up and therefore, we offer very good packages as our
              gratitude to our Associates. In order to be considered for this
              position, please use this form to give us any possible details
              about yourself and your experience in the industry. Our privacy
              policy does not permits us to disclose the names of our Associates
              and their profile shall thence be kept confidential.
            </p>

            <p>
              Please note that we cannot respond to general questions and/or
              information requests submitted through this page and this form
              will not help you receive customer support. For customer support,
              please go to &nbsp;
              <Link
                className="contact-link"
                to={PageRoutesConstant.Page.ContactUs.path}
              >
                Contact us
              </Link>
              &nbsp; page.
            </p>

            <RequestFrom formSource="become-associate" />
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default BecomeAssociate;
