import { FC } from "react";
import { Col, Row } from "react-bootstrap";
import "./BlogHeader.style.scss";

type BlogHeaderProps = {
  headerText: string;
};

const BlogHeader: FC<BlogHeaderProps> = ({ headerText }) => {
  return (
    <Row className="g-0 blog-header">
      <Col xs={12}>
        <div className="content">
          <h1 className="text mb-0">{headerText}</h1>
        </div>
      </Col>
    </Row>
  );
};
export default BlogHeader;
