import { FC } from "react";
import { Structure1 } from "../../assets/images";
import { Col, Row } from "react-bootstrap";

const RevitStructure: FC = () => {
  return (
    <Row className="g-0">
      <Col xs={12} className="divider">
        <div className="content">
          <p>
            We provide professional Revit Structure 3D CAD and Revit drafting
            services to Architects, Engineers, Manufacturers and contractors.
          </p>

          <p>
            RevitMart delivers coordinated construction documents at all times,
            providing enormous time savings and quality improvements in drawing
            deliverables.
          </p>

          <p>
            RevitMart delivers coordinated construction documents at all times,
            providing enormous time savings and quality improvements in drawing
            deliverables.
          </p>

          <p>
            <strong>
              Below are some of the benefits we provide when you use RevitMart
              as your BIM Consultant:
            </strong>
          </p>

          <p>
            <i className="bi bi-check-lg"></i> We provide automatic
            sheet/drawing references which ensures that no section, elevation,
            or callout ever references the wrong drawing or sheet
            <br />
            <i className="bi bi-check-lg"></i> Bidirectional associativity to
            ensure all changes to any part of the building need to be made only
            once
            <br />
            <i className="bi bi-check-lg"></i> Conceptual cost estimating
            <br />
            <i className="bi bi-check-lg"></i> Ability to modify geometry by
            typing a new number, saving time during iterative design processes
            <br />
            <i className="bi bi-check-lg"></i> Integrated building materials
            <br />
            <i className="bi bi-check-lg"></i> Integrated project phasing
            <br />
            <i className="bi bi-check-lg"></i> Parametric design
            <br />
            <i className="bi bi-check-lg"></i> Project templates
            <br />
            <i className="bi bi-check-lg"></i> Relationships between parametric
            components
            <br />
            <i className="bi bi-check-lg"></i> Single multiuser building
            database
            <br />
            <i className="bi bi-check-lg"></i> Butt-glazed curtain walls
            <br />
            <i className="bi bi-check-lg"></i> Demolition and construction
            phasing
            <br />
            <i className="bi bi-check-lg"></i> Prints sets/saved print settings
            <br />
            <i className="bi bi-check-lg"></i> Scale-based line weight control
            <br />
            <i className="bi bi-check-lg"></i> Title block families
            <br />
            <i className="bi bi-check-lg"></i> Change history audit trail
            <br />
            <i className="bi bi-check-lg"></i> Graphic controls for linked Revit
            models
            <br />
            <i className="bi bi-check-lg"></i> Multiuser worksets
            <br />
            <i className="bi bi-check-lg"></i> On-demand database reading
            <br />
            <i className="bi bi-check-lg"></i> Runtime shared parameters
            <br />
            <i className="bi bi-check-lg"></i> Rendering
            <br />
            <i className="bi bi-check-lg"></i> Animated walkthroughs
            <br />
            <i className="bi bi-check-lg"></i> Material take-off for detailed
            cost estimating
            <br />
            <i className="bi bi-check-lg"></i> Interface with Excel spreadsheets
          </p>

          <p>
            PS: ‘RevitMart’ is a place where you can find everything you need
            related to your Revit® needs!
          </p>

          <Row>
            <Col xs={12}>
              <img
                className="img-fluid"
                src={Structure1}
                alt="Revit Structure 3D Drafting Services"
                title="Revit Structure 3D Drafting Services"
              />
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};
export default RevitStructure;
