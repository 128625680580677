export const ContactInfoList = [
  {
    description:
      "For any questions regarding AutoCAD Drafting, Revit Drafting at RevitMart and it’s affiliations, please Contact us at:",
    address: {
      addressLine1: "3022 Javier Rd,",
      addressLine2: "Fairfax, Virginia 22031",
    },
    tollFree: "202-780-7004",
    office: "",
    email: "Info@RevitMart.com",
    iframeSource:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3107.201036389876!2d-77.2739977843263!3d38.85075495729663!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b64c162c28de39%3A0xd3bede412fa7f8a4!2s3601+Pickett+Road%2C+Fairfax%2C+VA+22031!5e0!3m2!1sen!2sus!4v1554688039570!5m2!1sen!2sus",
  },
  {
    description: "",
    address: {
      addressLine1: "276 Fifth Ave,",
      addressLine2: "New York, NY 10001",
    },
    tollFree: "",
    office: "202-780-7004",
    email: "Info@RevitMart.com",
    iframeSource:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.7298406747323!2d-73.98909818459401!3d40.74596977932829!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c259a89d04e4e9%3A0xf229fd2cc19090f!2s276+5th+Ave%2C+New+York%2C+NY+10001!5e0!3m2!1sen!2sus!4v1554687930146!5m2!1sen!2sus",
  },
  {
    description: "",
    address: {
      addressLine1: "2950 SE Stark St.",
      addressLine2: "Portland, OR 97214",
    },
    tollFree: "202-780-7004",
    office: "",
    email: "Info@RevitMart.com",
    iframeSource:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2795.5477325700012!2d-122.63741438417905!3d45.519182237793636!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5495a09592d87731%3A0x7cc82e2253d87bd8!2s2950+SE+Stark+St%2C+Portland%2C+OR+97214!5e0!3m2!1sen!2sus!4v1554688070058!5m2!1sen!2sus",
  },
  {
    description: "",
    address: {
      addressLine1: "190 S LaSalle St",
      addressLine2: "Chicago, IL 60603",
    },
    tollFree: "202-780-7004",
    office: "",
    email: "Info@RevitMart.com",
    iframeSource:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11882.445145936159!2d-87.632655!3d41.879709!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa684f83a85dc1ee5!2sIntelligent+Office!5e0!3m2!1sen!2sus!4v1554688240734!5m2!1sen!2sus",
  },
  {
    description: "",
    address: {
      addressLine1: "6080 Center Dr",
      addressLine2: "Los Angeles, CA 90045",
    },
    tollFree: "202-780-7004",
    office: "",
    email: "Info@RevitMart.com",
    iframeSource:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3308.6122458642476!2d-118.39376108478695!3d33.97680458062649!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2b74cddd1b3a9%3A0xf29052825c0e9e84!2s6080+Center+Dr%2C+Los+Angeles%2C+CA+90045!5e0!3m2!1sen!2sus!4v1554688399145!5m2!1sen!2sus",
  },
];
