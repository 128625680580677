import { FC, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import AutoHideAlert from "../alert/AutoHideAlert";
import { RevitMartContactFormService } from "../../services/RevitMartContactFormService";
import { IFormData } from "../../interfaces/FormData";
import { Captcha } from "../recaptcha/Recaptcha";
import "./ContactForm.style.scss";

const Contact: FC = () => {
  const initialFormData: IFormData = {
    source: "contact-us",
    name: "",
    email: "",
    phone: "",
    message: "",
  };

  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState<IFormData>(initialFormData);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [captchaError, setCaptchaError] = useState<string>();
  const [captchaToken, setCaptchaToken] = useState<string | undefined>(
    undefined
  );

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      if (captchaToken) {
        setIsLoading(true);
        await RevitMartContactFormService(formData)
          .then((response) => {
            if (response) {
              setFormData(initialFormData);
              setSuccessMessage(
                "Your message has been sent and someone will be in touch with you shortly"
              );
              setIsLoading(false);
              setValidated(false);
              setCaptchaToken(undefined);
            }
          })
          .catch((error) => {
            console.error("Error in form submission:", error);
            setIsLoading(false);
            setErrorMessage("Failed to send message.");
          });
      } else {
        setCaptchaError("Please confirm that you are not a robot.");
      }
    }
  };

  return (
    <div className="contact">
      <Row>
        <Col xs={12}>
          <div className="heading mb-3">Contact Us</div>
        </Col>
        <Col xs={12}>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Control
              type="text"
              name="source"
              value="revitmart"
              readOnly
              hidden
            />
            <Row className="g-3">
              <Col xs={12}>
                <Form.Control
                  type="text"
                  name="name"
                  required
                  placeholder="Name"
                  autoComplete="off"
                  onChange={handleChange}
                  value={formData.name}
                />
              </Col>
              <Col xs={12}>
                <Form.Control
                  type="email"
                  name="email"
                  required
                  placeholder="Email"
                  autoComplete="off"
                  onChange={handleChange}
                  value={formData.email}
                />
              </Col>
              <Col xs={12}>
                <Form.Control
                  type="number"
                  name="phone"
                  required
                  placeholder="Phone"
                  autoComplete="off"
                  onChange={handleChange}
                  value={formData.phone}
                />
              </Col>
              <Col xs={12}>
                <Form.Control
                  as="textarea"
                  name="message"
                  rows={4}
                  required
                  autoComplete="off"
                  placeholder="Message"
                  onChange={handleChange}
                  value={formData.message}
                />
              </Col>
              <Col xs={12}>
                <Captcha
                  error={captchaError}
                  captchaToken={captchaToken}
                  setCaptchaToken={setCaptchaToken}
                  setCaptchaError={setCaptchaError}
                />
              </Col>
              <Col xs={12}>
                <Button variant="dark" type="submit" disabled={isLoading}>
                  {isLoading ? "Sending" : "Send"}
                </Button>
              </Col>
              <Col xs={12}>
                {successMessage && (
                  <AutoHideAlert
                    variant="success"
                    message={successMessage}
                    duration={3000}
                    setMessage={setSuccessMessage}
                  />
                )}
                {errorMessage && (
                  <AutoHideAlert
                    variant="danger"
                    message={errorMessage}
                    duration={3000}
                    setMessage={setErrorMessage}
                  />
                )}
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default Contact;
