import { FC } from "react";
import { Col, Row } from "react-bootstrap";
import { AdminLogo } from "../../../assets/images";
import { Link } from "react-router-dom";

const ChooseBimDesignSolutions: FC = () => {
  return (
    <Row className="g-5">
      <Col xs={12}>
        <div className="title">
          <h1 className="heading">Why Choose our BIM design solutions?</h1>
        </div>

        <p className="text-decoration-underline">
          <strong>Why Choose our BIM design solutions</strong>
        </p>
        <p>
          With so many architectural companies mushrooming all over the world,
          one is spoilt for choice and often gets confused when it comes to
          choosing the best option for
          <strong> 3D Drafting Company! </strong>
          After all, who doesn’t want the best solution for their personal or
          business needs?
        </p>

        <p>
          With the heavy demand from the industry, as far as, AutoCAD designs
          are concerned, &nbsp;
          <Link className="link-tag" to="http://www.revitmart.com">
            RevitMart
          </Link>
          &nbsp; has evolved with a view to provide the best solutions with the
          most updated technology so that any architect or company can find a
          one-stop shop specializing in AutoCAD related services, as the company
          delivers its products in a timely and most cost effective manner.
        </p>

        <p>
          Companies such as Revitmart at &nbsp;
          <Link className="link-tag" to="http://www.revitmart.com">
            www.revitmart.com
          </Link>
          &nbsp; strive to offer the best quality solutions that take the load
          of work off your architects who are left to focus on creating and
          delivering the quality work you want. Our Architectural Drafting
          Services are sure to lighten your workload, while at the same time
          providing you with top-quality drafting and designing output.
        </p>

        <p>
          At &nbsp;
          <Link className="link-tag" to="http://www.revitmart.com">
            www.revitmart.com
          </Link>
          &nbsp;we believe in delivering the best designs that meet our clients’
          criteria, while at the same time adhering to highly competitive time
          constraints.
        </p>

        <p className="text-decoration-underline">
          Advantages of <strong>AutoCAD </strong> and
          <strong> REVIT Drafting</strong>
        </p>

        <ul>
          <li className="my-3">
            <strong>AutoCAD </strong> and
            <strong> REVIT Drafting </strong>services ensure that true 3D
            solutions are created for all your architectural and drafting needs.
            <strong> REVIT services </strong> such as those offered by Revitmart
            will never accidentally miss any details.
          </li>
          <li className="my-3">
            Professional and high quality
            <strong> REVIT services </strong> ensure that you have full
            engineering data, giving the details of the entire list of
            equipment.
          </li>
        </ul>

        <p>
          The Architectural <strong>CAD Drafting </strong>by &nbsp;
          <Link className="link-tag" to="http://www.revitmart.com">
            www.revitmart.com
          </Link>
          &nbsp; includes the following:
        </p>

        <ul>
          <li className="my-3">
            The <strong>CAD Drafting Services</strong> replicated from a
            free-hand sketch
          </li>
          <li className="my-3">Conversion from a paper drawing / PDF file</li>
          <li className="my-3">
            Drafting and Editing CAD files from redlined PDF and markups
          </li>
          <li className="my-3">Conversions from the CAD to other platforms</li>
          <li className="my-3">Complete CAD Documentation service</li>
        </ul>

        <p>
          We have the best architects and drafters on our team that boasts
          unmatched qualifications that are backed with a truly global
          experience. Our quality assurance team ensures accurate and reliable
          data.
        </p>

        <p className="text-decoration-underline">
          Why chose our <strong>CAD Drafting services?</strong>
        </p>

        <p>
          The success or failure of any product design finally rests on the
          processes which lead to the identification of potential errors and
          eliminating them right at the onset. Our Computer Aided Design (CAD) &
          Drafting Services find favor with many of the manufacturers worldwide
          since we specialize in dealing with the increasing complexity and
          demands of the technical designs. Our teams take pride in their
          capability to unlock the most complex of the designs when it comes to
          providing
          <strong> CAD Drafting </strong>or any other services. We rank amongst
          the leading service providers in the world to offer the complete
          <strong> CAD Drafting services </strong>that range from simple
          2D-drafting to more complex 3D-Visualizations.
        </p>

        <p>
          As <strong>3D Drafting Company</strong>&nbsp;
          <Link className="link-tag" to="http://www.revitmart.com">
            www.revitmart.com
          </Link>
          &nbsp; helps our clients in controlling the workflow and integrating
          their business. We have delivered the best of cost effective business
          solutions to many a leading firm across the globe. Our
          <strong> 3D Drafting Company </strong>project managers are highly
          experienced and skilled and can easily handle all aspects of the
          <strong> CAD Drafting Services</strong> and
          <strong> MEP Drafting Services</strong> projects seamlessly.
        </p>

        <p>
          <i className="fa fa-tag" /> 3D Drafting Company, 3D Drafting Services,
          AutoCAD Revit, CAD Drafting, CAD Drafting Services, Engineering
          Drafting Services, MEP Drafting Services, Revit Company, Revit
          Drafting, Revit Drafting Service, Revit Services
        </p>
      </Col>
      <Col xs={12}>
        <div className="entry-author">
          <img src={AdminLogo} alt="admin" />
          <p className="writer-name">
            Written by <span className="text-dark">admin</span>
          </p>
        </div>
      </Col>
    </Row>
  );
};
export default ChooseBimDesignSolutions;
