import { FC } from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { NavbarConstant } from "./MainNavbar.constant";
import "./MainNavbar.style.scss";

const MainNavbar: FC = () => {
  const location = useLocation();

  return (
    <div className="navbar py-0">
      <Navbar expand="xl" className="w-100">
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          className="ms-auto px-3"
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="m-auto">
            {NavbarConstant.map((item, index) =>
              item.child ? (
                <NavDropdown
                  key={index}
                  title={item.title}
                  id={`nav-dropdown-${index}`}
                >
                  {item.child.map((subItem, subIndex) => (
                    <NavDropdown.Item
                      key={subIndex}
                      as={Link}
                      to={subItem.path}
                      className={
                        location.pathname === subItem.path ? "active" : ""
                      }
                    >
                      {subItem.title}
                    </NavDropdown.Item>
                  ))}
                </NavDropdown>
              ) : (
                <Nav.Link
                  key={index}
                  as={Link}
                  to={item.path}
                  className={location.pathname === item.path ? "active" : ""}
                >
                  {item.title}
                </Nav.Link>
              )
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};
export default MainNavbar;
