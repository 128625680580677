import { FC } from "react";
import { Link } from "react-router-dom";
import { socialMediaLinks } from "./MainFooter.constant";
import { PageRoutesConstant } from "../router/components/PageRoutes";
import "./MainFooter.style.scss";

const MainFooter: FC = () => {
  return (
    <div className="footer p-0">
      <div className="content">
        <div className="link">
          <div className="social">
            <label>Follow Us</label>
            {socialMediaLinks.map((link, index) => (
              <a key={index} href={link.url} target="_blank" rel="noreferrer">
                <i className={link.icon}></i>
              </a>
            ))}
          </div>
        </div>
      </div>
      <div className="info">
        <div className="content px-3 px-md-5">
          <p className="w-100">
            Copyright © 2008-{`${new Date().getFullYear()}`} RevitMart®
          </p>
          <p className="w-100">
            <Link to={PageRoutesConstant.Page.Representative.path}>
              Have a Representative Contact Me
            </Link>
            <strong> | </strong>
            <Link to={PageRoutesConstant.Page.BecomeAssociate.path}>
              Become an Associate
            </Link>
            <strong> | </strong>
            <Link to="/sitemap"> Sitemap</Link>
            <strong> | </strong>
            <Link to={PageRoutesConstant.Page.ContactUs.path}>
              Contact us
            </Link>
            <strong> | </strong>
            <Link to={PageRoutesConstant.Page.Blog.path}> Blog</Link>
          </p>
        </div>
      </div>
    </div>
  );
};
export default MainFooter;
