import { FC, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import AutoHideAlert from "../../components/alert/AutoHideAlert";
import { RevitMartContactFormService } from "../../services/RevitMartContactFormService";
import { IFormData } from "../../interfaces/FormData";
import { Captcha } from "../../components/recaptcha/Recaptcha";
import "./Representative.style.scss";

const Representative: FC = () => {
  const initialFormData: IFormData = {
    source: "representative-contact",
    name: "",
    company: "",
    city: "",
    state: "",
    phone: "",
    email: "",
    message: "",
    origin: "",
    information: "",
  };

  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState<IFormData>(initialFormData);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [captchaError, setCaptchaError] = useState<string>();
  const [captchaToken, setCaptchaToken] = useState<string | undefined>(
    undefined
  );

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    } else {
      if (captchaToken) {
        setIsLoading(true);
        await RevitMartContactFormService(formData)
          .then((response) => {
            if (response) {
              setFormData(initialFormData);
              setSuccessMessage(
                "Your message has been sent and someone will be in touch with you shortly"
              );
              setIsLoading(false);
              setValidated(false);
              setCaptchaToken(undefined);
            }
          })
          .catch((error) => {
            console.error("Error in form submission:", error);
            setIsLoading(false);
            setErrorMessage("Failed to send message.");
          });
      } else {
        setCaptchaError("Please confirm that you are not a robot.");
      }
    }
  };

  return (
    <div className="representative">
      <Row className="g-0">
        <Col xs={12} className="divider">
          <div className="content">
            <h4 className="heading">
              <strong>Have a Representative Contact Me</strong>
            </h4>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Control
                type="text"
                name="source"
                value="revitmart"
                readOnly
                hidden
              />
              <Row className="g-3 mb-3">
                <Col xs={12} md={10}>
                  <Form.Control
                    type="text"
                    name="name"
                    required
                    autoComplete="off"
                    placeholder="Name"
                    onChange={handleChange}
                    value={formData.name}
                  />
                </Col>
                <Col xs={12} md={10}>
                  <Form.Control
                    type="text"
                    name="company"
                    required
                    autoComplete="off"
                    placeholder="Company"
                    onChange={handleChange}
                    value={formData.company}
                  />
                </Col>
                <Col xs={12} md={10}>
                  <Form.Control
                    type="text"
                    name="city"
                    required
                    autoComplete="off"
                    placeholder="City"
                    onChange={handleChange}
                    value={formData.city}
                  />
                </Col>
                <Col xs={12} md={10}>
                  <Form.Control
                    type="text"
                    name="state"
                    required
                    autoComplete="off"
                    placeholder="State"
                    onChange={handleChange}
                    value={formData.state}
                  />
                </Col>
                <Col xs={12} md={8}>
                  <Form.Control
                    type="number"
                    name="phone"
                    required
                    autoComplete="off"
                    placeholder="Day Phone"
                    onChange={handleChange}
                    value={formData.phone}
                  />
                </Col>
                <Col xs={12} md={10}>
                  <Form.Control
                    type="email"
                    name="email"
                    required
                    autoComplete="off"
                    placeholder="Email"
                    onChange={handleChange}
                    value={formData.email}
                  />
                </Col>
                <Col xs={12} md={10}>
                  <Form.Control
                    as="textarea"
                    name="message"
                    required
                    autoComplete="off"
                    rows={10}
                    placeholder="Comments"
                    onChange={handleChange}
                    value={formData.message}
                  />
                </Col>
                <Col xs={12} md={10}>
                  <Row className="mb-3">
                    <Form.Label>Origin</Form.Label>
                    <Col>
                      <Form.Check
                        inline
                        label="Domestic (US/Canada)"
                        name="origin"
                        type="radio"
                        onChange={handleChange}
                        checked={formData.origin === "Domestic (US/Canada)"}
                        value="Domestic (US/Canada)"
                        required
                      />
                      <Form.Check
                        inline
                        label="International"
                        name="origin"
                        type="radio"
                        onChange={handleChange}
                        checked={formData.origin === "International"}
                        value="International"
                        required
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={4}>
                  <Form.Group controlId="information">
                    <Form.Label>Need for Information</Form.Label>
                    <Form.Select
                      name="information"
                      onChange={handleChange}
                      value={formData.information}
                      required
                    >
                      <option value="">Select Information</option>
                      <option value="Immediate">Immediate</option>
                      <option value="Urgent">Urgent</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col xs={12}>
                  <Captcha
                    error={captchaError}
                    captchaToken={captchaToken}
                    setCaptchaToken={setCaptchaToken}
                    setCaptchaError={setCaptchaError}
                  />
                </Col>
                <Col xs={12} md={10}>
                  <Button variant="dark" type="submit" disabled={isLoading}>
                    {isLoading ? "Sending" : "Send"}
                  </Button>
                </Col>
                <Col xs={12} md={10}>
                  {successMessage && (
                    <AutoHideAlert
                      variant="success"
                      message={successMessage}
                      duration={3000}
                      setMessage={setSuccessMessage}
                    />
                  )}
                  {errorMessage && (
                    <AutoHideAlert
                      variant="danger"
                      message={errorMessage}
                      duration={3000}
                      setMessage={setErrorMessage}
                    />
                  )}
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default Representative;
