import { FC } from "react";
import { Logo } from "../../assets/images";
import { Link, useNavigate } from "react-router-dom";
import { PageRoutesConstant } from "../router/components/PageRoutes";
import "./MainHeader.style.scss";

const MainHeader: FC = () => {
  const navigate = useNavigate();
  return (
    <div className="header">
      <div className="px-3 px-xl-5 header_content">
        <div className="w-100 w-lg-50 p-3 text-center text-md-start">
          <img
            className="img-fluid cursor-pointer"
            src={Logo}
            alt="logo"
            onClick={() => navigate(PageRoutesConstant.Page.Home.path)}
          />
        </div>
        <div className="w-100 w-lg-50 p-3 header_content_wrapper">
          <div>
            <div className="mb-3">
              <Link to={PageRoutesConstant.Page.Representative.path}>
                Have a Representative Contact Me
              </Link>
              |<Link to={PageRoutesConstant.Page.Careers.path}> Careers</Link> |
              <Link to={PageRoutesConstant.Page.ContactUs.path}>
                Contact us
              </Link>
            </div>
            <div className="px-1">
              <span>Toll Free :</span> 202-780-7004 | <span>Hours :</span> 8am -
              5pm EST
              <br />
              <span>Email :</span> Info@RevitMart.com
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MainHeader;
