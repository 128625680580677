import axios, { AxiosResponse } from "axios";
import { IFormData } from "../interfaces/FormData";

export const RevitMartContactFormService = async (
  data: IFormData
): Promise<AxiosResponse<any>> => {
  try {
    const apiUrl = process.env.REACT_APP_EMAIL_API_URL;
    if (!apiUrl) throw new Error("API URL is not defined");

    const response = await axios.post(
      `${apiUrl}/revitmart-contact-form`,
      data
    );

    return response;
  } catch (error) {
    console.error("Error in RevitMartContactFormService:", error);
    throw error;
  }
};
