import { useEffect, useState } from "react";
import { useDebounced } from "./useDebounced";

interface ResponsiveState {
  windowWidth: number;
  isMobile: boolean;
  isTablet: boolean;
}

const mobileMinWidth = 767.98;
const tabletMinWidth = 991.98;
const tabletMaxWidth = 1199.98;

const useResponsive = (): ResponsiveState => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isMobile, setIsMobile] = useState(window.innerWidth < mobileMinWidth);
  const [isTablet, setIsTablet] = useState(
    window.innerWidth >= tabletMinWidth && window.innerWidth <= tabletMaxWidth
  );

  const handleResize = useDebounced(200, () => {
    const width = window.innerWidth;
    setWindowWidth(width);
    setIsMobile(width < mobileMinWidth);
    setIsTablet(width >= tabletMinWidth && width <= tabletMaxWidth);
  });

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  return { windowWidth, isMobile, isTablet };
};

export default useResponsive;
