import { FC } from "react";
import { Kitchen1, Kitchen2 } from "../../assets/images";
import { Col, Row } from "react-bootstrap";

const RevitKitchen: FC = () => {
  return (
      <Row className="g-0">
        <Col xs={12} className="divider">
          <div className="content">
            <p>
              RevitMart has assisted many Restaurant/ Food Service Consultants
              in navigating the complex path to a successful restaurant opening.
              Highly regarded for their ability to assist with all phases of the
              restaurant development and restaurant design. RevitMart is able to
              help with specific challenges your idea or operation may face
              whether it be kitchen equipment, plumbing or HVAC related to
              kitchen design, we can help with all. RevitMart has proven helpful
              in coordinating Commercial Revit Kitchen all across New York City
              and Washington DC. We also provide services to renovate existing
              kitchens including piping, all kitchen equipment, lighting, fire
              protection, HVAC, structural reinforcements and architectural
              aspects. Our pre made Revit content is free for our clients to
              use. Our Laser Scanning patented technology and softwares make it
              easier than ever to design around any sort of existing conditions.
              We bring our industry expertise to the table for every project
              that we handle whether big or small. RevitMart has been involved
              with many restaurant chains like Essen, Corner Bakery, Manhattan
              Bagel, Chipotle, Apple-bees and many more. Many of our clients see
              our services essential to the success of any kitchen or
              foodservice establishment. We understand the challenges and
              responsibilities that come with any building project. We have
              extensive field experience and unique knowledge about working with
              multiple contractors, construction workers, tradespeople and
              manufacturers. We coordinate specific project requirements with
              manufacturers to provide customized equipment based on our the
              design.
            </p>

            <Row>
              <Col xs={12}>
                <img
                  className="img-fluid"
                  src={Kitchen1}
                  alt="Commercial Revit Kitchen"
                  title="Commercial Revit Kitchen"
                />
                <div className="fst-italic">Commercial Revit Kitchen</div>
              </Col>
              <Col xs={12}>
                <img
                  className="img-fluid"
                  src={Kitchen2}
                  alt="Commercial Revit Kitchen"
                  title="Commercial Revit Kitchen"
                />
                <div className="fst-italic">Commercial Revit Kitchen</div>
              </Col>
            </Row>
          </div>
        </Col>
    </Row>
  );
};
export default RevitKitchen;
