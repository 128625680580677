import { FC } from "react";
import { ClashDetection1 } from "../../assets/images";
import { Row, Col } from "react-bootstrap";

const ClashDetection: FC = () => {
  return (
    <Row className="g-0">
      <Col xs={12} className="divider">
        <div className="content">
          <p>
            We at RevitMart believe in and provide accurate and affordable clash
            detection services and resolution coordination with the help of our
            expert team that have years of experience in the construction
            industry. With the help of clash detection techniques, we have saved
            our clients hundreds of thousands of dollars. This is one of the
            reason our clients love us! We work with all latest industry
            softwares as desired by our clients. We are capable of issuing same
            day clash reports with detailed list of areas of conflict and
            possible solution based on our design and construction expertise.
          </p>

          <p>
            <strong>Clash detection and coordination services</strong>
          </p>

          <p>
            Our pre-construction coordination services helps reducing the amount
            of change orders during construction to minimize the possible risk
            of human error during design phase. Here are some of the services
            included in our clash/ coordination detection services.
          </p>

          <p>
            Navisworks clash detection
            <br />
            Industrial plants
            <br />
            Residential buildings
            <br />
            Commercial buildings
            <br />
            Government buildings
          </p>

          <p>
            <strong>Advantages of choosing our coordination services</strong>
          </p>

          <p>
            RevitMart can understand the need of the various types of clients
            including architects, designers, contractors, sub-contractors,
            developers and building owners. Here are some of the main advantages
            of choosing RevitMart for clash detection services.
          </p>

          <p>
            Automatically determine any possible change orders
            <br />
            Our clash report includes time-based clashes that can offer
            information about every static package involved in the clash.
            <br />
            The report helps project management team to make adjustments in the
            schedule of the project.
            <br />
            With the custom clashes, the expertise of users can create custom
            tests that can be used by everybody on the team for project
            development.
            <br />
            Clash software can also be used even after the completion of project
            design as well as during construction.
          </p>

          <p>
            The main aim of RevitMart is nothing but to provide clash detection
            services that ensures identification, inspection and reporting of
            interference between different trades in a BIM project.
          </p>
          <Row className="g-0">
            <Col xs={12}>
              <img
                className="img-fluid"
                src={ClashDetection1}
                alt="Navisworks Clash Detection"
                title="Navisworks Clash Detection"
              />
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};
export default ClashDetection;
