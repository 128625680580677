import { FC } from "react";
import { Col, Row } from "react-bootstrap";
import { AdminLogo } from "../../../assets/images";

const InsightMepDraftingServices: FC = () => {
  return (
            <Row className="g-5">
              <Col xs={12}>
                <div className="title">
                  <h1 className="heading">
                    An Insight into the MEP Drafting Services
                  </h1>
                </div>

                <p>
                  Electrical and hydraulic systems have implemented a variety of
                  architectural building MEP Drafting Services and MEP systems
                  engineering is applied. Details and development system design
                  and engineering design of good coordination between the
                  various areas of development aid for the MEP services have
                  been guaranteed.
                </p>
                <p>
                  The structure of each project, the residential or commercial,
                  and all integrated systems architectural style for the proper
                  functioning of mechanical, electrical and plumbing needs one.
                  MEP engineers to achieve sustainable development in all
                  aspects of construction to create a special service.
                </p>

                <p>
                  MEP Drafting services and MEP engineering services to various
                  agencies here provide details of the project.
                </p>

                <p>Mechanical Drawing Services:</p>

                <ul>
                  <li className="my-2">Heating and cooling system design.</li>
                  <li className="my-2">When fire protection system design.</li>
                  <li className="my-2">Design Tube</li>
                  <li className="my-2">To provide fire protection system.</li>
                </ul>

                <p>Electrical Service Painting:</p>

                <ul>
                  <li className="my-2">Power Map</li>
                  <li className="my-2">Programming electrical panel.</li>
                  <li className="my-2">Light Plan</li>
                  <li className="my-2">Power Distribution Plan</li>
                  <li className="my-2">Electric cables and wire drawing.</li>
                </ul>

                <p>Figure Installation Services:</p>

                <ul>
                  <li className="my-2">Model water system in the country.</li>
                  <li className="my-2">Pipeline</li>
                  <li className="my-2">drains Design</li>
                  <li className="my-2">Shop Drawings</li>
                  <li className="my-2">
                    Piping isometric drawings and paintings between.
                  </li>
                  <li className="my-2">Plans and water distribution program</li>
                </ul>

                <p>An Insight into the MEP Drafting Services</p>

                <p>
                  The design engineering technology, computer-aided software
                  tools to provide the exact words written MEP MEP MEP MEP MEP
                  to use various effective: AutoCAD, Revit, Navisworks as. Micro
                  station, carrier-ejaculate and EPLAN etc.
                </p>

                <p>
                  MEP software architecture modeling tools for drawing and other
                  equipment suitable and you can help make building design
                  synchronization between effective. MEP and the various
                  components of the device for the virtual component is equipped
                  with a comprehensive library. The designers and engineers,
                  according to customer specifications allow developing the full
                  MEP drafting services.
                </p>

                <p>
                  Also, modern features to complete MEP software tools used for
                  document service. The program is embedded in an effective
                  structure to represent the transparency can be secured for
                  each replacement.
                </p>

                <p>
                  <i className="fa fa-tag" /> 3D Drafting Company, 3D Drafting
                  Services, AutoCAD Revit, CAD Drafting, CAD Drafting Services,
                  Engineering Drafting Services, MEP Drafting Services, Revit
                  Company, Revit Drafting, Revit Drafting Service, Revit
                  Services
                </p>
              </Col>
              <Col xs={12}>
                <div className="entry-author">
                  <img src={AdminLogo} alt="admin" />
                  <p className="writer-name">
                    Written by <span className="text-dark">admin</span>
                  </p>
                </div>
              </Col>
            </Row>
  );
};
export default InsightMepDraftingServices;
