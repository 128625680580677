import { FC } from "react";
import { MepDraftingService } from "../../../assets/images";
import { Row, Col } from "react-bootstrap";

const MepDraftingServices: FC = () => {
  return (
    <Row className="g-0">
      <Col xs={12} className="divider">
        <div className="content">
          <p>
            We provide professional 2D and 3D CAD Revit and
            <strong> MEP Drafting Services | </strong> Revitmart to Architects,
            Engineers, Manufacturers and contractors within the building
            services sector. Working with mechanical, electrical and plumbing
            design engineers, consultants and contractors, we convert as-builts
            and marked-up documents or CAD files into Revit models. Over the
            years, we have satisfied the needs of many engineering firms in
            Washington DC and New York. We provided Services to many equipment
            manufacturers to develop their BIM content and give our clients the
            benefit of having access to all out developed material and Trademark
            software.
          </p>

          <p>
            <strong>Our Experience Spans a Range of Sectors, Including:</strong>
          </p>
          <p>
            High Rise Office Buildings
            <br />
            High Rise Condos
            <br />
            Large Footprint Commercial Buildings
            <br />
            Shopping Malls
            <br />
            HealthCare Facilities and Hospitals
            <br />
            Laboratories
            <br />
            Complex Central Plants
          </p>

          <p>
            We utilize the latest technology in our Company to communicate and
            share files using web-based online collaboration systems and
            sophisticated mark-up tools.
          </p>

          <p>
            <strong>As a part of our MEP drafting services:</strong>
          </p>
          <p>
            Schematic MEP Drawings
            <br />
            Design Documents for MEP
            <br />
            Construction Drawings for MEP
            <br />
            As-built MEP Drawings
            <br />
            Ensure that there are no clashes (this can be validated easily using
            clash detection software tools like Navisworks)
            <br />
            By using the latest BIM modelling software for coordination in MEP
            Drafting Services | Revitmart, we are able to verify that all trades
            are fully coordinated
            <br />
            Creating Revit® Families That Look 99.9% Real
            <br />
            Detailed Energy Modelling using BIM
            <br />
            Converting 2D Files Into Informative 3D Content
            <br />
            Converting 2D AutoCAD® Drawings Into Real Time Intelligent 3D
            Systems
            <br />
            Making Revit® Families In Accordance With The Various Engineering
            Code Standards
            <br />
            Making Company Templates
            <br />
            Specialized Rendering Services & Walkthrough Movies
            <br />
            Modifying Current 3D Content As Per Your Needs/Company Standards
            <br />
            Checking Your Deliverable Revit® Model For Any Inconsistencies
            Amongst Its Systems throughout the project
          </p>
          <p>
            PS: ‘RevitMart’ is a place where you can find Our services and
            everything you need related to your Revit® needs!
          </p>
          <Row className="g-0">
            <Col xs={12}>
              <img
                className="img-fluid"
                src={MepDraftingService}
                alt="Engineering 3D Revit MEP Drafting Services"
                title="Engineering 3D Revit MEP Drafting Services"
              />
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};
export default MepDraftingServices;
