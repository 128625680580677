import { FC, useEffect, useState } from "react";
import CarouselSlider from "../../carousel-slider/CarouselSlider";
import MainFooter from "../../footer/MainFooter";
import MainHeader from "../../header/MainHeader";
import MainNavbar from "../../navbar/MainNavbar";
import PageRoutes, { PageRoutesConstant } from "./PageRoutes";
import Contact from "../../contact-form/ContactForm";
import { Col, Row } from "react-bootstrap";
import Breadcrumbs from "../../breadcrumbs/Breadcrumbs";
import { useLocation } from "react-router-dom";

const PageFlowRoutes: FC = () => {
  const [currentPageName, setCurrentPageName] = useState<string>();
  const location = useLocation();
  const isContactHide = [
    PageRoutesConstant.Page.Home.path,
    PageRoutesConstant.Page.RevitAsBuilts.path,
    PageRoutesConstant.Page.LaserScan.path,
    PageRoutesConstant.Page.ContactUs.path,
  ].includes(location.pathname);

  useEffect(() => {
    const key = Object.keys(PageRoutesConstant.Page).find(
      (item) => PageRoutesConstant.Page[item].path === location.pathname
    );
    if (key) {
      setCurrentPageName(PageRoutesConstant.Page[key].title);
    }
  }, [location.pathname]);

  return (
    <div className="main">
      <div className="layout container-md">
        <header>
          <MainHeader />
          <MainNavbar />
        </header>
        <div className="pages">
          <CarouselSlider />
          <div className="tag-line">{currentPageName}</div>
          <Row className="g-0">
            {location.pathname !== PageRoutesConstant.Page.Home.path && (
              <Col xs={12}>
                <Breadcrumbs currentPageName={currentPageName} />
              </Col>
            )}
          </Row>
          <Row className="g-0">
            <Col xs={12} lg={isContactHide ? 12 : 8}>
              <PageRoutes />
            </Col>
            {!isContactHide && (
              <Col xs={12} lg={4}>
                <div className="content">
                  <Contact />
                </div>
              </Col>
            )}
          </Row>
        </div>
        <footer>
          <MainFooter />
        </footer>
      </div>
    </div>
  );
};

export default PageFlowRoutes;
